import React, { FC, useEffect, useMemo, useState } from "react";
import "./SecretMessage.scss";
import { sentenceMessage, sentences, unlockWords } from "utils/wordsDiscovery";
import { SecretMessageFC } from "models/SecretMessageFC";
import { selectMe, useResetCountUnseenWordsMutation } from "store/features/authSlice";
import { useSelector } from "react-redux";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";

/**
 * SecretMessage Component
 *
 * @param {boolean} props.trigger - If true invokes the function of the word discovery.
 * @param {Dispatch<SetStateAction<boolean>>} props.setTrigger - Set the boolean variable for the function of the word discovered.
 */

export const SecretMessage: FC<Partial<SecretMessageFC>> = React.memo(
  ({ trigger, setTrigger, startSpeech, setIsFinished }) => {
    const currentUser = useSelector(selectMe);

    // Local state for managing displayed sentences
    const [sentencesToShow, setSentencesToShow] = useState<sentenceMessage[]>(sentences);

    const [start, setStart] = useState<boolean>(false);
    // Mutation hook for resetting the count of unseen words
    const [resetUnseenWords] = useResetCountUnseenWordsMutation();
    const { volumeVoice } = useEffectAudio();

    useEffect(() => {
      if (
        trigger &&
        currentUser &&
        currentUser[0] &&
        currentUser[0].unlockedWords !== 0 &&
        sentencesToShow
      ) {
        setSentencesToShow(unlockWords(currentUser[0].unlockedWords));

        currentUser[0].countUnseenWords > 0 && resetUnseenWords(currentUser[0]._id);
      }
      setTrigger && setTrigger(false);
      setStart(true);
    }, [currentUser, resetUnseenWords, setTrigger, sentencesToShow, trigger]);

    const renderSpeech = useMemo(() => {
      if (Boolean(volumeVoice) && startSpeech) {
        return (
          <SpeechSynthesis
            notVisible
            start={start && startSpeech}
            texts={[
              `Il messaggio dice:`,
              ...sentencesToShow?.map(({ visible }) => visible).filter((word) => word),
            ]}
            setFinished={setIsFinished}
          />
        );
      }
    }, [sentencesToShow, setIsFinished, start, startSpeech, volumeVoice]);

    return (
      <div data-cy="secret-message" className="container-secret-message">
        <div className="text-message">
          {sentencesToShow && (
            <>
              {sentencesToShow?.map((elem, index) => {
                return (
                  <div key={index} className="single-verse">
                    <span>{elem?.visible || ""} </span>
                    <span className="text-hidden">{elem?.hidden || ""}</span>
                  </div>
                );
              })}
              {renderSpeech}
            </>
          )}
        </div>

        <div className="container-img-message container-image-scene">
          <img
            className="img-message"
            src={require(`assets/img/common/elements/message.svg`).default}
            alt="message"
          />
        </div>
      </div>
    );
  }
);
