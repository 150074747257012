type WebpackRequireContext = {
    keys: () => string[];
    (id: string): any;
    resolve(id: string): string;
    id: string;
};

export interface ImageMap {
    [key: string]: string;
}

export function importAll(r: WebpackRequireContext): ImageMap {
    const images: ImageMap = {};
    r.keys().forEach((key: string) => (images[key] = r(key)));
    return images;
}

export const getImage = (value?: string) => {
    return value && value.split("/").splice(1)[0].split(".").splice(0)[0];
};
