import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { FlowScene, ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import { EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { RootState } from "../store";
// State model
export type ModeGameData = {
    type: TypeGameEnum;
    mode: ModeGameEnum;
    game?: EnumPath.PARROT_GAME | EnumPath.TOUCAN_GAME | EnumPath.SLOTH_GAME | "";
    flowScene?: FlowScene;
    currentScene: SceneEnum;
    currentSequence: number[][];
    timerPaused?: boolean;
    isFirstGame?: boolean;
};

const initialState: ModeGameData = {
    type: TypeGameEnum.START_MODAL_DEMO,
    mode: ModeGameEnum.FORWARD_GAME,
    game: "",
    timerPaused: false,
    currentScene: SceneEnum.EMPTY,
    currentSequence: [],
    isFirstGame: true,
};
// Create Redux Slice
const modeGameSlice = createSlice({
    name: "mode-game", // Nome dello slice
    initialState, // Stato iniziale
    reducers: {
        // A reduce for game modality
        setModeGame: (
            state,
            action: PayloadAction<
                Omit<ModeGameData, "currentScene" | "repeatTutorial" | "currentSequence">
            >
        ) => {
            return {
                ...state,
                type: action.payload.type,
                mode: action.payload.mode,
            };
        },

        setFlowScene: (state, action: PayloadAction<FlowScene>) => {
            return {
                ...state,
                flowScene: action.payload,
            };
        },

        setCurrentScene: (state, action: PayloadAction<SceneEnum>) => {
            return {
                ...state,
                currentScene: action.payload,
            };
        },
        setCurrentSequence: (state, action: PayloadAction<number[][]>) => {
            return {
                ...state,
                currentSequence: action.payload,
            };
        },
        setGame: (
            state,
            action: PayloadAction<
                EnumPath.PARROT_GAME | EnumPath.TOUCAN_GAME | EnumPath.SLOTH_GAME | ""
            >
        ) => {
            return {
                ...state,
                game: action.payload,
            };
        },

        setTimerPaused: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                timerPaused: action.payload,
            };
        },

        setIsFirstGame: (state, action: PayloadAction<boolean>) => {
            return {
                ...state,
                isFirstGame: action.payload,
            };
        },
    },
});

export const {
    setModeGame,
    setCurrentScene,
    setCurrentSequence,
    setIsFirstGame,
    setTimerPaused,
    setFlowScene,
    setGame,
} = modeGameSlice.actions;
export const modeGameSelector = (state: RootState) => state.modeGame;
export default modeGameSlice.reducer;
