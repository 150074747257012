import flowers from "assets/img/toucan/elements/flowers.svg";
import { ActionCard } from "components/ActionCard/ActionCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { CardIconEnum } from "models/CardIconEnum";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC } from "react";
import getClasses from "utils/getClasses";
import "./ToucanFwdIntroStart.scss";
import flowerPlant from "assets/img/toucan/elements/intro-start-foreground-plant.svg";
import toucanOnaTree from "assets/img/toucan/elements/intro-start-toucan.svg";

/**
 * ToucanFwdIntroStart Component
 *
 * This component represents a started chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const contents: SceneDataT = [
  {
    title: "La ricerca del piccolo tucano ",
    text: [
      "Al piccolo Tuco piace allontanarsi spesso dal nido da solo per esplorare la foresta. Ogni volta che lo fa, la sua mamma corre a cercarlo.",
      "Questa volta però, nonostante vari tentativi, pare che Tuco non si riesca a trovare da nessuna parte.",
    ],
    images: {
      foregroundPlant: flowerPlant,
      flowers: flowers,
      toucan: toucanOnaTree,
    },
  },
];

export const ToucanFwdIntroStart: FC<Partial<ChapterSceneFC>> = ({ out, action, page = 1 }) => {
  const [
    {
      title,
      text,
      images: { foregroundPlant, flowers, toucan },
    },
  ] = contents;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  return (
    <div
      className={`chapter-started-container-toucan fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="card-center">
        <SpeechSynthesis
          notVisible
          texts={[title, ...text]}
          setFinished={() => setIsFinished(true)}
        />
        <ActionCard
          iconImg={CardIconEnum.LENS}
          title={title}
          buttonText="Avanti"
          text={text}
          action={action}
        />
      </div>

      <img
        className="foreground-plant"
        src={foregroundPlant}
        alt="A flower among leaves on the foreground"
      />
      <img className="img-flowers" src={flowers} alt="flowers" />
      <img
        className="toucan"
        src={toucan}
        alt="Toucan mommy is liyng on a tree on the top right of the screen"
      />
    </div>
  );
};
