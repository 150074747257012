import React from "react";
import { Home } from "../pages/private/Home/Home";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { LoginPage } from "../pages/public/LoginPage/LoginPage";
import PrivateRoutes from "../utils/PrivateRoutes";
import { GameChapters } from "pages/private/gameChapters/GameChapters";
import { GamesContainer } from "pages/private/Games/Container/GamesContainer";
import { ContainerGame } from "pages/private/Games/Container/ContainerGame";
import { InterludesContainer } from "pages/private/interludes/InterludesContainer";
import { EnumGame, EnumPath } from "models/EnumPath";

const Router = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <PrivateRoutes route="auth" />, //only if logged in
      children: [
        {
          path: EnumPath.DASHBOARD,
          element: <Home />,
          children: [
            {
              path: "",
              element: (
                <Navigate
                  to={`/${EnumPath.DASHBOARD}/${EnumPath.GAME_CHAPTERS}`}
                  replace={true}
                />
              ),
            },
            {
              path: EnumPath.GAME_CHAPTERS,
              element: <GameChapters />,
            },
            {
              path: `${EnumPath.INTRO}/:id`,
              element: <InterludesContainer game="intro" />,
            },
          ],
        },
        {
          path: EnumPath.GAMES,
          element: <GamesContainer />,
          children: [
            {
              path: `${EnumPath.MESSAGE}/:id`,
              element: <InterludesContainer game="message" />,
            },
            {
              path: EnumPath.PARROT_GAME,
              element: <ContainerGame game={EnumGame.PARROT_GAME} />,
            },
            {
              path: EnumPath.TOUCAN_GAME,
              element: <ContainerGame game={EnumGame.TOUCAN_GAME} />,
            },
            {
              path: EnumPath.SLOTH_GAME,
              element: <ContainerGame game={EnumGame.SLOTH_GAME} />,
            },
            {
              path: `${EnumPath.PARROT_GAME}/${EnumPath.SCENE}/:id`,
              element: <InterludesContainer game="parrot" />,
            },
            {
              path: `${EnumPath.TOUCAN_GAME}/${EnumPath.SCENE}/:id`,
              element: <InterludesContainer game="toucan" />,
            },
            {
              path: `${EnumPath.SLOTH_GAME}/${EnumPath.SCENE}/:id`,
              element: <InterludesContainer game="sloth" />,
            },
          ],
        },
        {
          path: "",
          element: <Navigate to={`/${EnumPath.DASHBOARD}`} replace={true} />,
        },
      ],
    },
    {
      path: EnumPath.LOGIN,
      element: <PrivateRoutes route="no-auth" />,
      children: [
        {
          path: "",
          element: <LoginPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Router;
