import React, { FC } from "react";
import "./Notification.scss";
import { NotificationFC } from "models/NotificationFC";

export const Notification: FC<NotificationFC> = ({ value = 0 }) => {
  //husky test
  return value !== 0 ?
      <div className="container-notification">
        <div className="notification">{value}</div>
      </div>
    : null;
};
