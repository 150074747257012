import door from "assets/img/parrot/elements/door-parrot.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC } from "react";
import getClasses from "utils/getClasses";
import "./ParrotEndSceneOne.scss";

/**
 * ParrotEndSceneOne Component
 *
 * This component represents the end of a chapter in the story.
 * It displays a fading-in image of a door.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotEndSceneOneData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
];

export const ParrotEndSceneOne: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const contents = parrotEndSceneOneData;
  useSceneAutoPlay({ page, contents, action: action! });

  return (
    <div
      className={`parrot-end-scene-one fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img className="door" src={door} alt="A half-open door from which light rays emerge" />
    </div>
  );
};
