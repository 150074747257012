import { FC, useMemo } from "react";
import "./ParrotSpinoffSceneOne.scss";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import { SceneEnum } from "models/EnumScene";
import { getTransitionClass } from "utils/getTransitionClass";
import felixImg from "assets/img/common/Detective/detective-memo.svg";
import pinaImage from "assets/img/parrot/elements/monkey-incentive.svg";
import yellowGreenParrot from "assets/img/parrot/elements/yellow-green-parrot-fixed.svg";
import plantParrots from "assets/img/parrot/elements/plant-parrots.svg";
import smallYellowParrot from "assets/img/parrot/elements/small-yellow-parrot.svg";
import blueParrot from "assets/img/parrot/elements/blue-parrot.svg";
import smallRedParrot from "assets/img/parrot/elements/small-red-parrot.svg";
import { useEffectAudio } from "hook/useEffectAudio";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

/**
 * ParrotSpinoffSceneOne Component
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of the scene inside component
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const spinoffData: IMultiOptionScene = {
  spinoffFirst: [
    {
      title: "Pina la scimmia cappuccina",
      text: [
        "Siamo riusciti a liberare la maggior parte dei pappagalli ma alcuni di loro sono rimasti intrappolati!",
        "Ogni volta che completerai il gioco ne libererai uno.",
      ],
      images: {
        pina: pinaImage,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Per liberare Coco, memorizza i quadrati che si illuminano e poi toccali nell’ordine corretto.",
      ],
      images: {
        pina: pinaImage,
        felix: felixImg,
      },
    },
  ],
  spinoffSecond: [
    {
      title: "Pina la scimmia cappuccina",
      text: [
        "Siamo riusciti a liberare un altro dei pappagalli ma alcuni sono ancora in gabbia. Continua a giocare per liberarli tutti!",
      ],
      images: {
        pina: pinaImage,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Per liberare Rita, memorizza i quadrati che si illuminano e poi toccali nell’ordine corretto.",
      ],
      images: {
        pina: pinaImage,
        felix: felixImg,
      },
    },
  ],
};

type dataKeyT = keyof typeof spinoffData;

export const ParrotSpinoffSceneOne: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentScene } = useSelector(modeGameSelector);
  const { volumeVoice } = useEffectAudio();
  const dataKey = currentScene.split("-")[0] as dataKeyT;

  const contents: SceneDataT = spinoffData[dataKey];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(() => {
    return (
      Boolean(volumeVoice) &&
      page && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          setFinished={() => setIsFinished(true)}
        />
      )
    );
  }, [contents, volumeVoice, page, setIsFinished]);

  return (
    <div
      className={`container-spinoff-first-one fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img className="img-plant-parrots-bottom" src={plantParrots} alt="plant-parrots" />
      <img className="img-yellow-parrot" src={smallYellowParrot} alt="yellow-parrot" />
      <img className="img-blue-parrot" src={blueParrot} alt="blue-parrot" />
      <img className="img-red-parrot" src={smallRedParrot} alt="red-parrot" />

      {currentScene?.includes(SceneEnum.SPINOFF_SECOND) && (
        <img
          className="img-green-yellow-parrot"
          src={yellowGreenParrot}
          alt="green-yellow-parrot"
        />
      )}

      {Array.from({ length: contents.length }).map((item, index) => {
        const {
          text,
          title,
          images: { pina, felix },
        } = contents[index];

        return (
          page &&
          page === index + 1 && (
            <div key={`${index}-${item}`} className="page-container">
              <div
                className={`container-comic-${index + 1} ${getTransitionClass([
                  page,
                  index + 1,
                ])}`}
              >
                <SimpleCard title={title} text={text} />
                {renderSpeech}
              </div>
              <img className="img-monkey" src={pina} alt="pina" />
              {page === 2 && (
                <img className="img-detective fade-in-image" src={felix} alt="felix" />
              )}
            </div>
          )
        );
      })}
    </div>
  );
};
