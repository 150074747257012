import handIcon from "assets/img/icons/pointer-sloth-game.svg";
import { Modal } from "components/Modal/Modal";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import useGetCurrentUser from "hook/useGetCurrentUser";
import { ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import { EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  modeGameSelector,
  setCurrentScene,
  setGame,
  setModeGame,
} from "store/slices/modeGameSlice";
import "./SlothGame.scss";

import detectiveOk from "assets/img/common/Detective/detective_sloth_game_ok.svg";
import detectiveSpeak from "assets/img/common/Detective/detective_sloth_game_speak.svg";
import { DemoSlothGame } from "components/DemoGame/DemoSlothGame/DemoSlothGame";
import { GenericButton } from "components/GenericButton/GenericButton";
import { RoundButton } from "components/RoundButton/RoundButton";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { Square } from "components/Square/Square";
import useEditSessionGame from "hook/useEditSessionGame";
import { useEffectAudio } from "hook/useEffectAudio";
import { useSlothGame } from "hook/useSlothGame";
import { SlothScore } from "models/Session";
import { useNavigate } from "react-router-dom";
import { setSlothScore } from "store/slices/currentSessionSlice";
import getClasses from "utils/getClasses";
import extractTextFromReactElement from "utils/extractTextFromReactElement";

export const nBackTutorialInfo: Record<number, string> = {
  1: "alla precedente",
  2: "a quella mostrata due volte prima",
  3: "a quella mostrata tre volte prima",
};

export const SlothGame = React.memo(() => {
  const { updateCurrentSession } = useEditSessionGame();
  const dispatch = useDispatch();
  const modeGame = useSelector(modeGameSelector);
  const { currentUser } = useGetCurrentUser();
  const { currentScene } = useSelector(modeGameSelector);
  const [isModalDemoOpen, setIsModalDemoOpen] = useState(true);
  const [isModalGameOpen, setIsModalGameOpen] = useState(false);
  const [isModalEndGameOpen, setIsModalEndGameOpen] = useState(false);
  const [isStopSpeech, setIsStopSpeech] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const {
    checkUserChoiceAndScore,
    skippedCard,
    sequence, // setNBack
    nBack,
    setScore,
  } = useSlothGame();
  const [tutorialStep, setTutorialStep] = useState<number>(0);
  const [deckAnimationStep, setDeckAnimationStep] = useState<number>(0);
  const [showHand, setShowHand] = useState(false);
  const [tooltip, setTooltip] = useState({ isShow: false, text: "" });
  const deckRef = useRef<HTMLDivElement | null>(null);
  const balloonRef = useRef<HTMLDivElement | null>(null);
  const targetCoupleFirstCard = 3;
  const targetCard = targetCoupleFirstCard + nBack;
  const navigate = useNavigate();
  const { volumeVoice, toggleCurrentMusic } = useEffectAudio();

  // useState for card click management
  const [clickedCards, setClickedCards] = useState<boolean[]>(
    new Array(sequence.length).fill(false)
  );

  const tutorialInstructions = useMemo(() => {
    return {
      title: "Detective Felix",
      text: [
        "Vedrai delle immagini comparire al centro dello schermo.",
        "Osservale con attenzione, alcune si ripeteranno più volte.",
        `Tocca il riquadro ogni volta che l’immagine che vedi`,
        "Adesso che hai capito come funziona il gioco, facciamo una prova!",
      ],
    };
  }, []);

  const simpleCardText = useMemo(() => {
    if (tutorialInstructions.text) {
      switch (tutorialStep) {
        case 0:
        case 1:
          return tutorialInstructions.text[0];
        case targetCard:
          return (
            <>
              {tutorialInstructions.text[2]}{" "}
              <strong className="tesi-simple-box-bold">{`è identica ${nBackTutorialInfo[nBack]}`}</strong>
            </>
          );
        case targetCard + 2:
          return tutorialInstructions.text[3];
        default:
          return tutorialInstructions.text[1];
      }
    }
  }, [tutorialInstructions, tutorialStep, targetCard, nBack]);

  const textModal = useMemo(() => {
    if (isModalDemoOpen) {
      //TODO fix condition based on nback variation
      return (
        currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
          "Prima di procedere, vuoi fare una prova?"
        : nBack !== 1 ?
          !modeGame.isFirstGame ?
            `Ricorda, dovrai toccare il riquadro ogni volta che l’immagine che vedi è identica ${nBackTutorialInfo[nBack]}`
          : "Ora presta molta attenzione perché le regole del gioco sono un po’ cambiate!"
        : "Prima di procedere, ti mostrerò come funziona il gioco"
      );
    } else if (isModalEndGameOpen) {
      return "Ben Fatto. Ci siamo quasi!";
    } else {
      return currentScene.includes(SceneEnum.SPINOFF_SCENE) ? "Giochiamo!" : (
          `Ok, sei pront${currentUser?.gender === "MALE" ? "o" : "a"}. Giochiamo!`
        );
    }
  }, [
    currentScene,
    currentUser?.gender,
    isModalDemoOpen,
    isModalEndGameOpen,
    modeGame.isFirstGame,
    nBack,
  ]);

  useEffect(() => {
    isModalDemoOpen &&
      dispatch(
        setModeGame({
          mode: modeGame.mode,
          type: TypeGameEnum.START_MODAL_DEMO,
        })
      );
  }, [dispatch, isModalDemoOpen, modeGame.mode]);

  const closeModal = useCallback(
    (
      type:
        | TypeGameEnum.TRAINING_TUTORIAL
        | TypeGameEnum.PLAYING_TUTORIAL
        | TypeGameEnum.SPEECH_GAME
        | TypeGameEnum.PLAY_GAME,
      repeat?: boolean
    ) => {
      // reset tutorial score and store update
      const initialScore: SlothScore = { rightCouples: 0, wrongCouples: 0, skippedCouples: 0 };
      setScore(initialScore);
      dispatch(
        setSlothScore({
          gamePart: `${
            modeGame.mode === ModeGameEnum.REVERSE_GAME ? "secondPart" : "firstPart"
          }`,
          score: initialScore,
        })
      );
      setIsFadingOut(true);
      if (repeat) {
        setIsModalDemoOpen(true);
        setIsModalGameOpen(false);
      }
      setTimeout(() => {
        const mode = modeGame.mode;
        type === TypeGameEnum.TRAINING_TUTORIAL && setIsModalDemoOpen(false);
        type === TypeGameEnum.PLAY_GAME && setIsModalGameOpen(false);
        dispatch(
          setModeGame({
            type: repeat ? TypeGameEnum.PLAY_GAME : type,
            mode,
          })
        );
        setIsFadingOut(false);
      }, 750);
    },
    [dispatch, modeGame.mode, setScore]
  );

  useEffect(() => {
    dispatch(setGame(EnumPath.SLOTH_GAME));
  }, [dispatch]);

  useEffect(() => {
    toggleCurrentMusic("music", "demo1");
  }, [toggleCurrentMusic]);

  const animateCard = useCallback(
    (cardIndex: number) => {
      if (cardIndex < deckRef.current?.childNodes.length!) {
        // get current card Element
        const currentCard = deckRef.current!.children[cardIndex];
        const targetCard = sequence[cardIndex] === sequence[cardIndex - nBack];
        const isCurrentCardSelected =
          currentCard.children[0].classList.contains("selected-with-image");
        const isTutorial = modeGame.type === TypeGameEnum.PLAYING_TUTORIAL;
        // card animation start handler
        const swipeInCard = () => {
          currentCard.classList.add("swipe-in");
          currentCard.addEventListener("animationend", (e) => {
            const event = e as AnimationEvent;
            if (event.animationName === "swipe-in") {
              swipeOutCard();
            }
          });
        };
        // card animation end handler
        const swipeOutCard = () => {
          //targetCard && console.log("giusta", sequence[cardIndex], sequence[cardIndex - nBack]);
          setTimeout(() => {
            //check missed answer and set a visual feedback
            if (!isCurrentCardSelected && targetCard) {
              if (isTutorial) {
                currentCard.children[0].classList.add("skipped-with-image");
                setTooltip({ isShow: true, text: "Presta più attenzione" });
                currentCard.classList.add("skipped");
              }
              //update score on skipped card
              skippedCard();
            } else if (isTutorial && isCurrentCardSelected && !targetCard) {
              setTooltip({ isShow: true, text: "Riprova" });
              currentCard.classList.add("skipped");
            }
            currentCard.classList.add("swipe-out");
            currentCard.addEventListener("animationend", (e) => {
              const event = e as AnimationEvent;
              if (event.animationName === "swipe-out") {
                resetCard();
              }
            });
          }, 500);
        };
        // go to the next card and reset current one
        const resetCard = () => {
          setClickedCards(new Array(sequence.length).fill(false));
          setTooltip({ isShow: false, text: "" });
          currentCard.classList.remove("swipe-in", "swipe-out", "skipped");
          currentCard.children[0].classList.remove("skipped-with-image", "selected-with-image");
          // set new state to trigger animateCard again
          setDeckAnimationStep((prev) => prev + 1);
        };
        swipeInCard();
      }
    },
    [modeGame.type, nBack, sequence, skippedCard]
  );

  const tutorialAnimation = useCallback(
    (step: number) => {
      const currentTutorialCard = deckRef.current?.children[step];
      const cardToClick = deckRef.current?.children[targetCard].children[0];
      const balloon = balloonRef.current!;
      const animateBalloon = () => {
        balloon.classList.add("move-down");
        balloon.addEventListener("animationend", moveBalloonUp);
      };
      const moveBalloonUp = () => {
        balloon.removeEventListener("animationend", moveBalloonUp);
        balloon.classList.add("move-up");
        balloon.addEventListener("animationend", resetBalloon);
      };
      const resetBalloon = () => {
        balloon.removeEventListener("animationend", resetBalloon);
        balloon.classList.remove("move-down", "move-up");
      };
      const swipeInCard = () => {
        if (step !== targetCoupleFirstCard && step !== targetCard) {
          currentTutorialCard?.classList.add("no-target");
        }
        currentTutorialCard?.classList.add("tutorial-card", "swipe-in");
        currentTutorialCard?.addEventListener("animationend", swipeOutCard);
      };
      const swipeOutCard = () => {
        currentTutorialCard?.removeEventListener("animationend", swipeOutCard);
        setTimeout(() => {
          if (step < targetCard) {
            currentTutorialCard?.classList.add("swipe-out");
            currentTutorialCard?.addEventListener("animationend", resetCard);
          }

          if (step === targetCard) {
            currentTutorialCard?.classList.add("zoom-in");
            setTimeout(nextStep, 3000);
          }
        }, 500);
      };
      const resetCard = () => {
        setClickedCards(new Array(sequence.length).fill(false));
        currentTutorialCard?.removeEventListener("animationend", resetCard);
        currentTutorialCard?.classList.remove(
          "tutorial-card",
          "no-target",
          "swipe-in",
          "swipe-out"
        );
        // set new state to trigger animateCard again
        setTimeout(nextStep, 500);
      };
      const nextStep = () => {
        setTutorialStep((prev) => prev + 1);
      };

      switch (step) {
        case 0:
          setIsStopSpeech(false);
          swipeInCard();
          break;
        case 2:
          setIsStopSpeech(false);
          animateBalloon();
          swipeInCard();
          break;
        case targetCard:
          setIsStopSpeech(false);
          animateBalloon();
          swipeInCard();
          setTimeout(() => setShowHand(true), 2000);
          break;
        case targetCard + 1:
          balloon.classList.add("move-down");
          cardToClick?.classList.add("selected-with-image");
          setTimeout(() => nextStep(), 2000);
          break;
        case targetCard + 2:
          balloon.classList.add("move-up");
          break;
        default:
          swipeInCard();
          break;
      }
    },
    [targetCard, sequence.length]
  );

  const animateTutorial = useCallback(() => {
    tutorialAnimation(tutorialStep); // Start animation with tutorial step counter
  }, [tutorialAnimation, tutorialStep]);

  const animateDeck = useCallback(() => {
    animateCard(deckAnimationStep); // Start animation with game step counter
  }, [animateCard, deckAnimationStep]);

  // useEffect to close modal & activate the game animation
  useEffect(() => {
    if (
      modeGame.type !== TypeGameEnum.START_MODAL_DEMO &&
      modeGame.type !== TypeGameEnum.SPEECH_GAME_TUTORIAL &&
      modeGame.type !== TypeGameEnum.TRAINING_TUTORIAL &&
      modeGame.type !== TypeGameEnum.PLAYING_TUTORIAL &&
      modeGame.type !== TypeGameEnum.PLAY_GAME
    ) {
      setIsModalDemoOpen(false);
    }
    if (
      modeGame.type !== TypeGameEnum.SPEECH_GAME_TUTORIAL &&
      [TypeGameEnum.PLAY_GAME, TypeGameEnum.PLAYING_TUTORIAL].includes(modeGame.type)
    ) {
      animateDeck();
    }
    modeGame.type === TypeGameEnum.START_MODAL_GAME && setIsModalGameOpen(true);
    modeGame.type === TypeGameEnum.END_MODAL_GAME && setIsModalEndGameOpen(true);
  }, [animateDeck, modeGame.type]);

  const navigateAfterGame = useCallback(
    (timeout: number = 2000) => {
      setTimeout(() => {
        if (
          modeGame.mode === ModeGameEnum.REVERSE_GAME &&
          modeGame.type === TypeGameEnum.PLAY_GAME
        ) {
          updateCurrentSession({ isReverseGame: false, completed: true });
          dispatch(
            setModeGame({
              type: TypeGameEnum.START_MODAL_DEMO,
              mode: ModeGameEnum.FORWARD_GAME,
            })
          );
        } else {
          updateCurrentSession({ isReverseGame: true, completed: false });
          dispatch(
            setModeGame({
              type: TypeGameEnum.START_MODAL_DEMO,
              mode: ModeGameEnum.REVERSE_GAME,
            })
          );
        }
        dispatch(
          setCurrentScene(
            modeGame.mode === ModeGameEnum.FORWARD_GAME ?
              modeGame.currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
                modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ?
                  SceneEnum.SPINOFF_FIRST_TWO
                : SceneEnum.SPINOFF_SECOND_TWO
              : SceneEnum.REVERSE_STARTED
            : modeGame.currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
              modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ?
                SceneEnum.SPINOFF_FIRST_THREE
              : SceneEnum.SPINOFF_SECOND_THREE
            : SceneEnum.END_ONE
          )
        );
        navigate(
          `/${EnumPath.GAMES}/${modeGame.game}/${EnumPath.SCENE}/${
            modeGame.mode === ModeGameEnum.FORWARD_GAME ?
              (
                modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ||
                modeGame.currentScene.includes(SceneEnum.SPINOFF_SECOND)
              ) ?
                SceneEnum.SPINOFF_FIRST_TWO
              : SceneEnum.REVERSE_STARTED
            : (
              modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ||
              modeGame.currentScene.includes(SceneEnum.SPINOFF_SECOND)
            ) ?
              SceneEnum.SPINOFF_FIRST_THREE
            : SceneEnum.END_ONE
          }`
        );
      }, timeout);
    },
    [
      dispatch,
      modeGame.currentScene,
      modeGame.game,
      modeGame.mode,
      modeGame.type,
      navigate,
      updateCurrentSession,
    ]
  );

  const dispatchAfterGame = useCallback(() => {
    if (
      modeGame.type === TypeGameEnum.PLAY_GAME &&
      modeGame.mode !== ModeGameEnum.REVERSE_GAME
    ) {
      setIsModalEndGameOpen(true);
    }
    dispatch(
      setModeGame({
        mode: modeGame.mode,
        type:
          modeGame.type === TypeGameEnum.PLAY_GAME ?
            TypeGameEnum.END_MODAL_GAME
          : TypeGameEnum.START_MODAL_GAME,
      })
    );
    if (
      modeGame.mode === ModeGameEnum.REVERSE_GAME &&
      modeGame.type === TypeGameEnum.PLAY_GAME
    ) {
      navigateAfterGame(0);
    }
  }, [dispatch, modeGame.mode, modeGame.type, navigateAfterGame]);

  useEffect(() => {
    if (isModalEndGameOpen) {
      navigateAfterGame();
    }
    // linter is wrong on this deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalEndGameOpen]);

  useEffect(() => {
    const mode = modeGame.mode;
    if (!volumeVoice && modeGame.type === TypeGameEnum.TRAINING_TUTORIAL)
      dispatch(
        setModeGame({
          type: TypeGameEnum.SPEECH_GAME_TUTORIAL,
          mode,
        })
      );
  }, [dispatch, modeGame.mode, modeGame.type, volumeVoice]);

  const resetTutorial = () => {
    // reset last card classes
    deckRef.current!.children[targetCard].classList.remove(
      "tutorial-card",
      "swipe-in",
      "zoom-in"
    );
    //reset balloon
    balloonRef.current?.classList.remove("move-down", "move-up");
    //unclick card
    deckRef.current?.children[targetCard].children[0].classList.remove("selected-with-image");
    // reset hand
    setShowHand(false);
    // reset counter
    setTutorialStep(0);
    // reset speech
    setIsStopSpeech(false);
  };

  useEffect(() => {
    // end game dispatch after deck last card
    if (sequence.length > 0 && deckAnimationStep === sequence.length) {
      dispatchAfterGame();
      setDeckAnimationStep(0);
    }
  }, [deckAnimationStep, dispatchAfterGame, sequence.length]);

  // useEffect to activate the tutorial animation
  useEffect(() => {
    if (modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL) {
      animateTutorial();
    }
  }, [animateTutorial, modeGame.type]);

  const renderSpeech = useMemo(
    () =>
      (
        modeGame.type !== TypeGameEnum.TRAINING_TUTORIAL &&
        modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL &&
        tutorialStep &&
        !isStopSpeech &&
        volumeVoice
      ) ?
        <SpeechSynthesis
          notVisible
          texts={[
            `${tutorialStep === 0 ? `${tutorialInstructions.title} dice:` : ""}`,
            `${extractTextFromReactElement(simpleCardText)}`,
          ]}
          start={Boolean(!isStopSpeech)}
          setFinished={() => setIsStopSpeech(true)}
        />
      : null,
    [
      modeGame.type,
      tutorialStep,
      isStopSpeech,
      volumeVoice,
      tutorialInstructions.title,
      simpleCardText,
    ]
  );

  const handleCardClick = (i: number) => {
    if (!clickedCards[i]) {
      checkUserChoiceAndScore(i);
      const newClickedCards = [...clickedCards];
      newClickedCards[i] = true;
      setClickedCards(newClickedCards);
    }
  };

  const clickableCondition =
    modeGame.type === TypeGameEnum.PLAY_GAME ||
    modeGame.type === TypeGameEnum.PLAYING_TUTORIAL ||
    !skippedCard;

  return (
    <>
      <Modal
        isOpen={isModalDemoOpen || isModalGameOpen || isModalEndGameOpen}
        onClose={() => closeModal(TypeGameEnum.TRAINING_TUTORIAL)}
      >
        <div className={`${isFadingOut ? "fade-out-image" : "fade-in-image"}`}>
          <DemoSlothGame
            data-cy="demo-modal"
            type="demo"
            action={() =>
              closeModal(
                isModalGameOpen ? TypeGameEnum.PLAY_GAME : TypeGameEnum.TRAINING_TUTORIAL
                //currentUser?.progressBar["sloth-game"]! > 0
              )
            }
            startTutorial={() => closeModal(TypeGameEnum.TRAINING_TUTORIAL)}
            reverse={modeGame.mode === ModeGameEnum.REVERSE_GAME}
            title={textModal}
          />
        </div>
      </Modal>

      {!isModalDemoOpen && !isModalGameOpen && !isModalEndGameOpen && (
        <div className={`sloth-game`}>
          <div ref={deckRef} className="sloth-game-deck">
            {sequence?.map((col, i) => (
              <Fragment key={`fragment-${col}-${i}`}>
                <div className="sloth-game-card" key={`${col}-${i}`}>
                  <Square
                    key={`square-${col}-${i}`}
                    element={col}
                    withIMG
                    action={() => handleCardClick(i)}
                    clickable={clickableCondition}
                  />
                  {tooltip.isShow && (
                    <div className="sloth-game-tooltip">
                      <SimpleCard title={tooltip.text} customTooltip />
                    </div>
                  )}
                </div>
                {i === targetCard &&
                  showHand &&
                  modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL && (
                    <img
                      key={`hand-${col}-${i}`}
                      className="tutorial-hand fade-in-image"
                      src={handIcon}
                      alt="hand"
                    />
                  )}
              </Fragment>
            ))}
          </div>
          {(modeGame.type === TypeGameEnum.TRAINING_TUTORIAL ||
            modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL) && (
            <>
              {tutorialStep === targetCard + 2 && (
                <>
                  <div className="tutorial-end-overlay fade-in-image" />
                  <div className="tutorial-replay-button">
                    <RoundButton action={resetTutorial} type="tutorial-replay" />
                  </div>
                  <div className="tutorial-end-button fade-in-image">
                    <GenericButton
                      type="button"
                      text="FAI UNA PROVA"
                      color="theme-purple"
                      action={() => {
                        closeModal(TypeGameEnum.PLAYING_TUTORIAL);
                        setTimeout(resetTutorial, 750); // wait transition before reset
                      }}
                      size="large"
                    />
                  </div>
                </>
              )}
              <div
                className={`detective-wrapper ${getClasses({
                  "fade-in-image": tutorialStep === 0 || tutorialStep === targetCard + 1,
                })}`}
              >
                <img
                  className="detective-figure"
                  src={tutorialStep < targetCard + 1 ? detectiveSpeak : detectiveOk}
                  alt="detective"
                />
                <div ref={balloonRef} className="balloon-container">
                  <SimpleCard title={tutorialInstructions.title} text={[simpleCardText!]} />
                  {[1, 2, targetCard, targetCard + 2].includes(tutorialStep) && renderSpeech}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
});
