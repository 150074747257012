import { EnumSecondGameSquare } from "models/EnumSecondGameSquare";
import { ImageMap, getImage, importAll } from "utils/getImage";
declare const require: any;

const images: ImageMap = importAll(
    require.context("../secondGameSquare", false, /\.(png|jpe?g|svg)$/)
);

export default images;

export const getSquareImg = (valueSquareImg: keyof typeof EnumSecondGameSquare) => {
    const filteredImages = Object.entries(images).find(
        ([item]) => getImage(item) === valueSquareImg.toLowerCase()
    );
    if (filteredImages) return filteredImages[1];
    return "";
};
