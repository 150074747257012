import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
    persistReducer,
    persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import tokenSlice from "./slices/tokenSlice";
import { apiSlice } from "./api/apiSlice";
import modeGameSlice from "./slices/modeGameSlice";
import audioSlice from "./slices/audioSlice";
import { authApiSlice } from "./features/authSlice";
import currentSessionSlice from "./slices/currentSessionSlice";

const persistConfig = {
    key: "root",
    storage,
    blacklist: [authApiSlice.reducerPath],
};

const rootReducer = combineReducers({
    [apiSlice.reducerPath]: apiSlice.reducer,
    token: tokenSlice,
    modeGame: modeGameSlice,
    audio: audioSlice,
    session: currentSessionSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof rootReducer>;
export const persistor = persistStore(store);
