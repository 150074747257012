import { FC } from "react";
import "./ParrotSpinoffSceneFour.scss";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import plantParrots from "assets/img/parrot/elements/plant-parrots.svg";
import smallYellowParrot from "assets/img/parrot/elements/small-yellow-parrot.svg";
import blueParrot from "assets/img/parrot/elements/blue-parrot.svg";
import smallRedParrot from "assets/img/parrot/elements/small-red-parrot.svg";
import yellowGreenParrotFree from "assets/img/parrot/elements/yellow-green-parrot.svg";
import yellowGreenParrotFixed from "assets/img/parrot/elements/yellow-green-parrot-fixed.svg";
import parrotAzur from "assets/img/parrot/elements/azure-parrot.svg";
import { SceneEnum } from "models/EnumScene";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

/**
 * ParrotSpinoffSceneFour Component
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotSpinoffSceneFourData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
];

export const ParrotSpinoffSceneFour: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentScene } = useSelector(modeGameSelector);

  const contents = parrotSpinoffSceneFourData;
  useSceneAutoPlay({ page, contents, action: action! });

  return (
    <div
      className={`container-spinoff-first-four fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img className="img-plant-parrots-bottom" src={plantParrots} alt="plant-parrots" />
      <img className="img-blue-parrot" src={blueParrot} alt="blue-parrot" />
      <img className="img-red-parrot" src={smallRedParrot} alt="red-parrot" />
      <img className="img-yellow-parrot" src={smallYellowParrot} alt="yellow-parrot" />
      {
        // Conditional rendering based on the scene
        currentScene?.includes(SceneEnum.SPINOFF_FIRST_FOUR) ?
          <img
            className="img-parrot-yellow-green-free fade-in-image"
            src={yellowGreenParrotFree}
            alt="yellow-green-parrot"
          />
        : <>
            <img
              className="img-parrot-azure-free fade-in-image"
              src={parrotAzur}
              alt="azure-parrot"
            />

            <img
              className="img-parrot-yellow-green-fixed fade-in-image"
              src={yellowGreenParrotFixed}
              alt="parrot"
            />
          </>

      }
    </div>
  );
};
