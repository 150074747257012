export enum EnumSecondGameSquare {
    BEE = "ape",
    BEARD = "barba",
    BOAT = "barca",
    BAG = "borsa",
    CATERPILLAR = "bruco",
    SOCK = "calza",
    DOG = "cane",
    GOAT = "capra",
    MEAT = "carne",
    HOME = "casa",
    CIRCUS = "circo",
    DICE = "dado",
    TOOTH = "dente",
    FINGER = "dito",
    GIFT = "regalo",
    DRAGON = "drago",
    GRASS = "erba",
    LIGHTHOUSE = "faro",
    FAIRY = "fata",
    THREAD = "filo",
    SEAL = "foca",
    SMOKE = "fumo",
    MUSHROOM = "fungo",
    LEG = "gamba",
    CAT = "gatto",
    WHEAT = "grano",
    OWL = "gufo",
    LIGHTNING = "lampo",
    WOOL = "lana",
    BOOK = "libro",
    MOON = "luna",
    WOLF = "lupo",
    WIZARD = "mago",
    HAND = "mano",
    SEA = "mare",
    APPLE = "mela",
    MOUNT = "monte",
    BLACKBERRIES = "more",
    FLY = "mosca",
    MOTORBIKE = "moto",
    WALL = "muro",
    DWARF = "nano",
    NOSE = "naso",
    SHIP = "nave",
    SNOW = "neve",
    NEST = "nido",
    WALNUTS = "noci",
    KNOT = "nodo",
    NOTES = "note",
    GOOSE = "oca",
    BEAR = "orso",
    BALL = "palla",
    BREAD = "pane",
    PEAR = "pera",
    FISH = "pesce",
    PINE = "pino",
    PIPE = "pipa",
    BRIDGE = "ponte",
    DOOR = "porta",
    BRANCH = "ramo",
    FROG = "rana",
    OAR = "remo",
    RICE = "riso",
    ROSE = "rosa",
    SALT = "sale",
    LADDER = "scala",
    BROOM = "scopa",
    SUN = "sole",
    SWORD = "spada",
    MOUSE = "topo",
    BULL = "toro",
    CAKE = "torta",
    TRAIN = "treno",
    TUBE = "tubo",
    GRAPES = "uva",
    BATH = "vasca da bagno",
    POT = "vaso",
    PAW = "zampa",
    ZEBRA = "zebra",
}

export type ValueMatrixFromEnum<K> = K extends Record<string, any> ? (keyof K)[][] : undefined;
