import React from "react";
import { ReactNode } from "react";

/**
 * Extracts the text content from a React element.
 *
 * @param {ReactNode} element The React element to extract text from.
 * @returns {string} The extracted text from the React element.
 *
 * Usage examples:
 *
 * - Extract text from a component to display it in another element.
 * - Debug components to understand the text content they render.
 * - Create accessibility features that read the text of React elements for screen reader users.
 */

const extractTextFromReactElement = (element: ReactNode) => {
    if (typeof element === "string") {
        return element;
    } else if (React.isValidElement(element)) {
        return React.Children.toArray(element.props.children)
            .map((child): string | ReactNode =>
                typeof child === "string" ? child : extractTextFromReactElement(child)
            )
            .join("");
    }
    return element;
};

export default extractTextFromReactElement;
