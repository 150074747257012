import React, { FC, useMemo } from "react";
import "./ToucanSpinoffSceneOne.scss";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import getClasses from "utils/getClasses";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import toucanMommy from "assets/img/toucan/elements/mother-toucan-left-spinoff.svg";
import pointingFelix from "assets/img/common/Detective/proof.svg";
import backpack from "assets/img/toucan/elements/backpack.svg";
import { getTransitionClass } from "utils/getTransitionClass";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

/**
 * ToucanSpinoffSceneOne Component
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of the scene inside component
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const toucanSpinoffSceneOneData: SceneDataT = [
  {
    title: "Mamma Tucano",
    text: [
      "Menomale che sei tornata!",
      "Abbiamo trovato degli zaini come questo sparsi per la foresta. Sembra proprio che ci siano altri cuccioli intrappolati! Ci aiuti a liberarli?",
    ],
    images: {
      toucan: toucanMommy,
    },
  },
  {
    title: "Detective Felix",
    text: [
      "Ogni volta che giocheremo, libereremo un tucano e saremo sempre più vicini al colpevole.",
      "Sei pronta a ispezionare il primo zaino?",
    ],
    images: {
      toucan: toucanMommy,
      felix: pointingFelix,
    },
  },
];

export const ToucanSpinoffSceneOne: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const contents = toucanSpinoffSceneOneData;
  const [
    {
      images: { toucan },
    },
  ] = contents;
  const { volumeVoice } = useEffectAudio();
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () =>
      Boolean(volumeVoice) &&
      page &&
      [1, 2].includes(page!) && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          start={Boolean(page)}
          setFinished={() => setIsFinished(true)}
        />
      ),
    [volumeVoice, page, contents, setIsFinished]
  );

  return (
    <div
      className={`toucan-spinoff-scene-one fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        <img className="backpack" src={backpack} alt="a misterious backpack on the ground" />
        <img className="toucan-figure" src={toucan} alt="Worried Toucan Mummy on a tree" />
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { felix },
          } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`scene-page${page}`}
                className={`page-container ${getTransitionClass([page, pageDiv + 1])}`}
              >
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {pageDiv === 1 && (
                  <img className="felix-figure" src={felix} alt="Detective Felix" />
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
