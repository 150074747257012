import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { pathMusic } from "data/musics";

interface AudioState {
    isSpeaking: boolean;
    volumeMusic: number;
    volumeVoice: number;
    currentMusic: string;
    effect: string;
}

const initialState: AudioState = {
    isSpeaking: false,
    volumeMusic: 0.3,
    volumeVoice: 0.3,
    currentMusic: pathMusic("music", "general"),
    effect: pathMusic("effect", "button1"),
};

export const audioSlice = createSlice({
    name: "audio",
    initialState,
    reducers: {
        setIsSpeaking: (state, action: PayloadAction<boolean>) => {
            state.isSpeaking = action.payload;
        },
        setVolumeMusic: (state, action: PayloadAction<number>) => {
            state.volumeMusic =
                action.payload >= 0 ? Math.min(1, action.payload) : 0;
        },
        setVolumeVoice: (state, action: PayloadAction<number>) => {
            state.volumeVoice =
                action.payload >= 0 ? Math.min(1, action.payload) : 0;
        },
        setCurrentMusic: (state, action: PayloadAction<string>) => {
            state.currentMusic = action.payload;
        },
        setEffect: (state, action: PayloadAction<string>) => {
            state.effect = action.payload;
        },
    },
});

export const {
    setIsSpeaking,
    setVolumeMusic,
    setVolumeVoice,
    setCurrentMusic,
    setEffect,
} = audioSlice.actions;

export const audioSelector = (state: RootState) => state.audio;

export default audioSlice.reducer;
