import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { AuthCredentials } from "models/AuthCredentials";

const initialState: AuthCredentials = {
    accessToken: "",
    expiresIn: null,
    refreshToken: "",
    tokenType: "",
    expiresDate: null,
};

export const tokenSlice = createSlice({
    name: "token",
    initialState,
    reducers: {
        setAuthCredentials: (state, action: PayloadAction<AuthCredentials>) => {
            if (action.payload.expiresIn) {
                return (state = {
                    ...state,
                    ...action.payload,
                    expiresDate: Date.now() + action.payload.expiresIn,
                });
            }
        },
        removeAuthCredentials: (state) => {
            return (state = { ...initialState });
        },
    },
});

export const { setAuthCredentials, removeAuthCredentials } = tokenSlice.actions;

export const tokenSelector = (state: RootState) => state.token;

export default tokenSlice.reducer;
