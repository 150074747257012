import { TypeGameEnum } from "models/EnumModeGame";
import { EnumGame, EnumPath } from "models/EnumPath";
import { CurrentSession, CurrentSessionResponse } from "models/Session";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetMeQuery } from "store/features/authSlice";
import { useUpdateSessionMutation } from "store/features/sessionSlice";
import { changeStatusSession, currentSessionSelector } from "store/slices/currentSessionSlice";
import { modeGameSelector, setModeGame } from "store/slices/modeGameSlice";

/**
 * useEditSessionGame Custom Hook: edit the current session.
 *
 * @param {boolean} isReverseGame - Indicates whether the session is in reverse mode.
 * @param {boolean} completed - Indicates whether the session is completed.
 */

const useEditSessionGame = () => {
  const dispatch = useDispatch();
  const modeGame = useSelector(modeGameSelector);
  const { id, userId, gameName, gameInfo } = useSelector(currentSessionSelector);
  const navigate = useNavigate();

  const updatedScore = useCallback(
    (gameName: string, completed: boolean) => {
      return gameName && gameName === EnumGame.SLOTH_GAME ?
          {
            firstPart: completed ? undefined : gameInfo.score.firstPart,
            secondPart: completed ? gameInfo.score.secondPart : undefined,
          }
        : {
            forwardScore: completed ? undefined : gameInfo.score.forwardScore,
            reverseScore: completed ? gameInfo.score.reverseScore : undefined,
          };
    },
    [gameInfo.score]
  );

  const [updateSession, { isUninitialized }] = useUpdateSessionMutation();
  const { refetch } = useGetMeQuery("");

  const updateCurrentSession = useCallback(
    async ({ isReverseGame, completed }: Record<string, boolean>) => {
      if (isUninitialized) {
        try {
          const data = {
            id,
            userId,
            gameName,
            gameInfo: {
              ...gameInfo.completedAt,
              isReverseGame,
              completed: completed,
              score: updatedScore(gameName, completed),
            },
          } as CurrentSession;

          const update = (await updateSession(data)) as CurrentSessionResponse;

          dispatch(
            changeStatusSession({
              isReverseGame,
              completed: completed,
              nBack: update.data.gameInfo.nBack,
            })
          );
          completed && (await refetch());
        } catch (error) {
          console.error(error);
          // if you do not interact with the game, play the initial demo again
          dispatch(
            setModeGame({
              mode: modeGame.mode,
              type: TypeGameEnum.START_MODAL_DEMO,
            })
          );

          if (modeGame.game === EnumPath.PARROT_GAME)
            navigate(`/${EnumPath.GAMES}/${EnumGame.PARROT_GAME}`);
          else if (modeGame.game === EnumPath.TOUCAN_GAME)
            navigate(`/${EnumPath.GAMES}/${EnumGame.TOUCAN_GAME}`);
          else if (modeGame.game === EnumPath.SLOTH_GAME)
            navigate(`/${EnumPath.GAMES}/${EnumGame.SLOTH_GAME}`);
          else navigate(`/${EnumPath.DASHBOARD}`);
        }
      }
    },
    [
      isUninitialized,
      updateSession,
      id,
      userId,
      gameName,
      gameInfo.completedAt,
      updatedScore,
      dispatch,
      refetch,
      modeGame.mode,
      modeGame.game,
      navigate,
    ]
  );

  return {
    updateCurrentSession,
  };
};

export default useEditSessionGame;
