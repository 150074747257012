import { EffectAudio } from "components/EffectAudio/EffectAudio";
import PortraitAlert from "components/PortrailtAlert/PortraitAlert";
import { useEffectAudio } from "hook/useEffectAudio";
import { EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { PrivateRoutesFC } from "models/PrivateRoutesFC";
import React, { FC, useMemo } from "react";
import ReactHowler from "react-howler";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { tokenSelector } from "store/slices/tokenSlice";

/**
 * PrivateRoutes component handles routing based on authentication status.
 *
 * @param {PrivateRoutesFC} route - The route to determine authentication behavior.
 * @returns {JSX.Element} - Returns the appropriate route based on authentication.
 */

const PrivateRoutes: FC<PrivateRoutesFC> = ({ route }) => {
  const location = useLocation();
  const { accessToken } = useSelector(tokenSelector);
  const { volumeMusic } = useEffectAudio();
  let result: React.ReactElement | null;

  // Check if EffectAudio should be hidden based on the current path
  const isOnLogin = location.pathname.split("/").some((elem) => elem === "login");

  if (route === "no-auth") {
    // Redirect to dashboard if authenticated, else render nested routes
    result =
      accessToken ?
        <Navigate to={`/${EnumPath.DASHBOARD}/${EnumPath.INTRO}/${SceneEnum.FORWARD_ONE}`} />
      : <Outlet />;
  } else {
    // Redirect to login if not authenticated, else render nested routes
    result = accessToken ? <Outlet /> : <Navigate to={`/${EnumPath.LOGIN}`} />;
  }
  const portraitAlertRenderer = useMemo(() => {
    if (isOnLogin) {
      return result;
    }
    return <PortraitAlert>{result}</PortraitAlert>;
  }, [isOnLogin, result]);

  return (
    <>
      {portraitAlertRenderer}
      {!isOnLogin && (
        <>
          <EffectAudio />
          <ReactHowler
            src={"assets/audio/total-background.m4a"}
            loop={true}
            volume={7}
            mute={!volumeMusic}
          />
        </>
      )}
    </>
  );
};

export default PrivateRoutes;
