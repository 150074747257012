import { WrapperGame } from "components/WrapperGame/WrapperGame";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import { ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import { EnumGame } from "models/EnumPath";
import { games } from "models/Games";
import "./ContainerGame.scss";
import { nBackTutorialInfo } from "../SlothGame";
import { useSlothGame } from "hook/useSlothGame";

interface IContainerGame {
  game: EnumGame;
}
export const ContainerGame = ({ game }: IContainerGame) => {
  const modeGame = useSelector(modeGameSelector);
  const Game = games[game];
  const { nBack } = useSlothGame();

  const gameInfo: Record<EnumGame, { title: string; description: string }> = {
    [EnumGame.PARROT_GAME]: {
      title: "La scomparsa dei pappagalli.",
      description: `Memorizza i quadrati che si illuminano e poi toccali nell'ordine ${
        modeGame.mode === ModeGameEnum.FORWARD_GAME ? "corretto" : "inverso"
      }.`,
    },
    [EnumGame.TOUCAN_GAME]: {
      title: "La ricerca del piccolo tucano.",
      description:
        (
          modeGame.type === TypeGameEnum.TRAINING_TUTORIAL ||
          modeGame.type === TypeGameEnum.SPEECH_GAME_TUTORIAL
        ) ?
          "Ascolta le parole e memorizzale."
        : `Tocca a te, tocca le immagini che corrispondono alle parole che hai ascoltato nell'ordine ${
            modeGame.mode === ModeGameEnum.FORWARD_GAME ? "corretto" : "inverso"
          }.`,
    },
    [EnumGame.SLOTH_GAME]: {
      title: "Il pisolino interrotto del bradipo",
      description: `Tocca il riquadro ogni volta che l’immagine che vedi è identica ${nBackTutorialInfo[nBack]}`,
    },
  };

  const propsWrapperGame = {
    timerGame: modeGame.mode === ModeGameEnum.FORWARD_GAME ? 120000 : 240000,
    title: gameInfo[game].title,
    description: gameInfo[game].description,
    children: <Game />,
  };

  return <WrapperGame {...propsWrapperGame} />;
};
