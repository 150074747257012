import alone from "assets/img/common/elements/alone-right.png";
import stantingSlothsTwo from "assets/img/sloth/elements/brady-sonny-spinoff-1.svg";
import rememberFelix from "assets/img/common/Detective/remember-zoom.svg";
import standingSlothsOne from "assets/img/sloth/elements/sonny-brady-start-spinoff-1.svg";
import layingSlothsTwo from "assets/img/sloth/elements/sonny-lula-2-start-spinoff-2.svg";
import layingSlothsOne from "assets/img/sloth/elements/sonny-lula-start-spinoff-2.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./SlothSpinoffSceneOne.scss";

const SlothSpinoffSceneOneData: IMultiOptionScene = {
  spinoffFirst: [
    {
      title: "Brady",
      text: [
        "Rieccoti qui! Oltre me, anche altri bradipi hanno bisogno del tuo aiuto per riavere i loro alberi.",
      ],
      images: {
        sloths: standingSlothsOne,
      },
    },
    {
      title: "Sonny",
      text: [
        "Brady mi ha detto che hai fatto un ottimo lavoro con il suo albero. Puoi far crescere velocemente anche il mio?",
      ],
      images: {
        sloths: stantingSlothsTwo,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocherai ci aiuterai a ripopolare la foresta.",
      ],
      images: {
        felix: rememberFelix,
      },
    },
  ],
  spinoffSecond: [
    {
      title: "Lula",
      text: [
        "Ti stavamo aspettando. Rivorrei tanto indietro il mio alberello! Mi aiuti a farlo crescere come hai fatto con gli altri?",
      ],
      images: {
        sloths: layingSlothsOne,
      },
    },
    {
      title: "Sonny",
      text: [
        "Lula ha tanto sonno ma ha bisogno del ramo di un albero per mettersi comodo. È abituata così, come tutti noi.",
      ],
      images: {
        sloths: layingSlothsTwo,
      },
    },
    {
      title: "Detective Felix",
      text: [
        "Ricordi come funziona? Ogni volta che giocherai ci aiuterai a ripopolare la foresta.",
      ],
      images: {
        felix: rememberFelix,
      },
    },
  ],
};
type dataKeyT = keyof typeof SlothSpinoffSceneOneData;

const SlothSpinoffSceneOne: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { currentScene } = useSelector(modeGameSelector);
  const { volumeVoice } = useEffectAudio();
  const dataKey = currentScene.split("-")[0] as dataKeyT;

  const contents: SceneDataT = SlothSpinoffSceneOneData[dataKey];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(() => {
    return (
      Boolean(volumeVoice) &&
      page && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          setFinished={() => setIsFinished(true)}
        />
      )
    );
  }, [contents, volumeVoice, page, setIsFinished]);

  return (
    <div
      className={`container-spinoff-one fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className={`content ${dataKey === "spinoffFirst" ? "bg-first" : "bg-second"}`}>
        {page === 3 && <div className="bg-overlay" />}
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { sloths, felix },
          } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`spinoff-page-${page}`}
                className={`page-content ${dataKey === "spinoffFirst" ? "first" : "second"} 
                ${getTransitionClass([page, pageDiv + 1])}`}
              >
                {[1, 2].includes(pageDiv + 1) && (
                  <img
                    src={sloths}
                    className={`sloths-figure sloths-${pageDiv + 1}`}
                    alt="Our Sloths Friends happy to see us"
                  />
                )}
                {pageDiv + 1 === 3 && (
                  <>
                    <img src={alone} className="alone" alt="an alone behind Felix" />
                    <img src={felix} className="felix-figure" alt="felix need our attention" />
                  </>
                )}
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

export default SlothSpinoffSceneOne;
