import { EnumGame } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { CurrentSession } from "models/Session";
import { apiSlice } from "store/api/apiSlice";

export const sessionApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        sessionActived: builder.query({
            query: (idCurrentUser: string) =>
                `/sessions/${idCurrentUser}/active-sessions`,
            providesTags: ["Session"],
        }),

        createSession: builder.mutation({
            query: (body: {
                userId: string;
                gameName: EnumGame;
                spinOffScene: SceneEnum;
            }) => ({
                url: "sessions",
                method: "POST",
                body: { ...body },
            }),
            invalidatesTags: ["Session"],
        }),

        updateSession: builder.mutation({
            query: (body: CurrentSession) => ({
                url: `sessions/${body.id}`,
                method: "PUT",
                body: { ...body },
            }),

            invalidatesTags: ["Session"],
        }),
    }),
});

export const {
    useLazySessionActivedQuery,
    useUpdateSessionMutation,
    useCreateSessionMutation,
} = sessionApiSlice;
