import happyMommy from "assets/img/toucan/elements/happy-mum-spinoff.svg";
import happyBrio from "assets/img/toucan/elements/small-toucan-fly.svg";
import happyLittleToucan from "assets/img/toucan/elements/small-toucan-happy.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import "./ToucanSpinoffSceneThree.scss";

/**
 * ToucanSpinoffSceneThree Component
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const toucanSpinoffSceneThreeData: SceneDataT = [
  {
    title: "Brio",
    text: ["Finalmente libero! Evviva!"],
    images: {
      toucan: happyMommy,
      brio: happyBrio,
      littleToucan: happyLittleToucan,
    },
  },
];
export const ToucanSpinoffSceneThree: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { volumeVoice } = useEffectAudio();
  const contents = toucanSpinoffSceneThreeData;
  const {
    title,
    text,
    images: { toucan, brio, littleToucan },
  } = contents[page - 1];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () =>
      Boolean(volumeVoice) &&
      page && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          start={Boolean(page)}
          setFinished={() => setIsFinished(true)}
        />
      ),
    [volumeVoice, page, contents, setIsFinished]
  );

  return (
    <div
      className={`toucan-spinoff-scene-three fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        <div className={`page-container`}>
          <img className="toucan-figure mommy" src={toucan} alt="Happy Mommy on a tree" />
          <div className="balloon-container">
            <SimpleCard title={title} text={text} />
          </div>
          {renderSpeech}
          <img className="toucan-figure little" src={littleToucan} alt="Happy little toucan" />
          <img className="toucan-figure brio" src={brio} alt="Happy flying Brio" />
        </div>
      </div>
    </div>
  );
};
