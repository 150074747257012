import backgroundBranch from "assets/img/toucan/elements/medium-branch.svg";
import foregroundPalm from "assets/img/toucan/elements/palm-left.svg";
import foregroundBranch from "assets/img/toucan/elements/small-branch.svg";
import blueToucan from "assets/img/toucan/elements/small-toucan-blue.svg";
import yellowToucan from "assets/img/toucan/elements/small-toucan-happy.svg";
import toucanMommy from "assets/img/toucan/elements/toucan-mum-right.svg";
import { ActionCard } from "components/ActionCard/ActionCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import { ChapterSceneFC, IMultiOptionScene, SceneDataT } from "models/ChapterSceneFC";
import { SceneEnum } from "models/EnumScene";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modeGameSelector, setCurrentScene } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import "./ToucanEndSceneTwo.scss";
import { CardIconEnum } from "models/CardIconEnum";

/**
 * ToucanEndSceneTwo Component
 *
 * This component represents the end of a chapter in the story.
 * It displays a fading-in image of a door.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const ToucanEndSceneTwoData: IMultiOptionScene = {
  "spinoffFirst-one": [
    {
      title: "Ce l'hai fatta!",
      text: [
        "Grazie per aver aiutato Brio! Dentro lo zaino ha trovato una nuova parola per il tuo messaggio da decifrare.",
        "Torna a giocare quando vuoi per liberare un altro cucciolo e trovare nuove parole!",
      ],
      images: {
        toucan: toucanMommy,
        littleToucan: blueToucan,
      },
    },
  ],
  "end-one": [
    {
      title: "Ce l'hai fatta!",
      text: [
        "Grazie per aver ritrovato il mio piccolo esploratore!",
        "So che hai bisogno di aiuto per decodificare un messaggio, ecco una parola. Torna a giocare  quando vuoi per collezionarne altre.",
      ],
      images: {
        toucan: toucanMommy,
        littleToucan: yellowToucan,
      },
    },
  ],
};
type DataKeyT = keyof typeof ToucanEndSceneTwoData;

export const ToucanEndSceneTwo: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { volumeVoice } = useEffectAudio();
  const dispatch = useDispatch();
  const { currentScene } = useSelector(modeGameSelector);
  const dataKey =
    (
      currentScene.includes(SceneEnum.SPINOFF_FIRST) ||
      currentScene.includes(SceneEnum.SPINOFF_SECOND)
    ) ?
      "spinoffFirst-one"
    : currentScene;
  const contents: SceneDataT = ToucanEndSceneTwoData[dataKey as DataKeyT];
  const { title, text, images } = contents[page - 1];

  const nextHandler = () => {
    dispatch(setCurrentScene(SceneEnum.MESSAGE_ONE));
    action && action();
  };

  return (
    <div
      className={`toucan-end-scene-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img
        src={backgroundBranch}
        className="right-branch"
        alt="a branch that goes behind the card comes from right"
      />
      {volumeVoice && <SpeechSynthesis notVisible={true} texts={[title, ...text]} />}
      <div className="card-center fade-in-image">
        <ActionCard
          iconImg={CardIconEnum.BAG}
          title={title}
          isEndGame
          text={text}
          buttonText={
            currentScene === SceneEnum.END_TWO ?
              "Vai al messaggio"
            : "Vai al messaggio in codice"
          }
          action={nextHandler}
        />
      </div>
      <img
        src={foregroundPalm}
        className="foreground-palm"
        alt="A palm from the top-left corner on the foreground"
      />
      <img
        src={foregroundBranch}
        className="left-branch"
        alt="a branch above the card comes from left"
      />
      <img
        src={images.littleToucan}
        className="toucan-figure little"
        alt="Little toucan lies on the left branch"
      />
      <img
        src={images.toucan}
        className="toucan-figure mommy"
        alt="Toucan Mommy lies on the background branch"
      />
    </div>
  );
};
