import { FC, useMemo } from "react";
import "./ToucanFwdIntroSceneOne.scss";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import getClasses from "utils/getClasses";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import toucanMommy from "assets/img/toucan/elements/toucan-mum-left.svg";
import { getTransitionClass } from "utils/getTransitionClass";
import detective from "assets/img/common/Detective/proof.svg";
import leaves from "assets/img/toucan/elements/leaves.svg";
import flowersGroup from "assets/img/toucan/elements/flowers-group.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

/**
 * ToucanFwdIntroSceneOne Component
 *
 * This component represents a first chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} page - Indicates number of the scene inside component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const contents: SceneDataT = [
  {
    title: "Mamma tucano",
    text: [
      "Ho volato in lungo e in largo per cercare Tuco, ma non c’è traccia di lui!",
      "Forse sarà intrappolato o nascosto da qualche parte…",
    ],
    images: {},
  },
  {
    title: "Mamma tucano",
    text: [
      "Mi aiutate a trovarlo?",
      "A volte gli piace volare fino al villaggio per giocare con i bambini.",
    ],
    images: {},
  },
  {
    title: "Detective Felix",
    text: [
      "Mentre la sua mamma continua a cercare Tuco perlustrando la foresta, noi andiamo al villaggio.",
    ],
    images: {
      felix: detective,
    },
  },
];

export const ToucanFwdIntroSceneOne: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { volumeVoice } = useEffectAudio();
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () =>
      Boolean(volumeVoice) && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          start={Boolean(page)}
          setFinished={() => setIsFinished(true)}
        />
      ),
    [page, volumeVoice, setIsFinished]
  );

  return (
    <div
      className={`toucan-intro-scene-one fade-in-image ${getClasses({
        "fade-out-image": out && page === 3,
      })}`}
    >
      <img className="img-toucan-mum" src={toucanMommy} alt="toucan-mum" />
      <img className="img-leaves" src={leaves} alt="leaves" />
      <img className="img-flowers" src={flowersGroup} alt="flowers" />

      <div className="content">
        {Array.from({ length: contents.length }).map((item, index) => {
          const {
            title,
            text,
            images: { felix },
          } = contents[index];
          return (
            page === index + 1 && (
              <div
                className={`page-container ${getTransitionClass([page, index + 1])}`}
                key={`${item}-${index}`}
              >
                <div className={`container-comic-${index + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {page === 3 && <img className="img-detective" src={felix} alt="detective" />}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
