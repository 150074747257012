import { useEffect, useState } from "react";
import usePortraitMode from "./usePortraitMode";

/**
 * useDeviceSize Custom Hook: get media query for javascript.
 *
 * @returns {Object} - Statements breakpoints media query.
 */

export const useDeviceSize = () => {
    const { windowDimensions } = usePortraitMode();

    const breakpointsSm = "(max-width: 767px) and (orientation: landscape)";
    const breakpointsMd = "(max-width: 1023px) and (orientation: landscape)";
    const breakpointsMdUp = "(min-width: 1024px)";
    const breakpointsLg = "(max-width: 1279px)";
    const breakpointsLgUp = "(min-width: 1280px)";
    const breakpointsXl = "(max-width: 1439px)";
    const breakpointsXxl = "(max-width: 1919px)";

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isMd, setIsMd] = useState<boolean>(false);
    const [isMdUp, setIsMdUp] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [isXl, setIsXl] = useState<boolean>(false);
    const [isLgUp, setIsLgUp] = useState<boolean>(false);
    const [isXxl, setIsXxl] = useState<boolean>(false);

    const updateIsMobile = () =>
        setIsMobile(window.matchMedia(breakpointsSm).matches);
    const updateIsMd = () => setIsMd(window.matchMedia(breakpointsMd).matches);
    const updateIsMdUp = () =>
        setIsMdUp(window.matchMedia(breakpointsMdUp).matches);
    const updateIsTablet = () =>
        setIsTablet(window.matchMedia(breakpointsLg).matches);
    const updateIsLgUp = () =>
        setIsLgUp(window.matchMedia(breakpointsLgUp).matches);
    const updateIsXl = () => setIsXl(window.matchMedia(breakpointsXl).matches);
    const updateIsXxl = () =>
        setIsXxl(window.matchMedia(breakpointsXxl).matches);

    useEffect(() => {
        if (window) {
            updateIsMobile();
            updateIsMd();
            updateIsMdUp();
            updateIsTablet();
            updateIsXl();
            updateIsLgUp();
            updateIsXxl();
        }
    }, [windowDimensions]);

    return {
        isMobile,
        updateIsMobile,
        isMd,
        updateIsMd,
        isMdUp,
        updateIsMdUp,
        isTablet,
        updateIsTablet,
        isXl,
        updateIsXl,
        isLgUp,
        updateIsLgUp,
        isXxl,
        updateIsXxl,
        windowDimensions,
    };
};
