import { EnumSlothGame } from "models/EnumSlothGame";
import { ImageMap, importAll, getImage } from "utils/getImage";

declare const require: any;

const images: ImageMap = importAll(
    require.context("../slothGameCard", false, /\.(png|jpe?g|svg)$/)
);

export default images;

export const getSlothGameImg = (valueSquareImg: keyof typeof EnumSlothGame) => {
    const filteredImages = Object.entries(images).find(
        ([item]) => getImage(item) === valueSquareImg.toLowerCase()
    );
    if (filteredImages) return filteredImages[1];
    return "";
};
