import { apiSlice } from "../api/apiSlice";
import { createEntityAdapter, createSelector } from "@reduxjs/toolkit";
import { CurrentUser } from "models/CurrentUser";

// Create an entity adapter for managing user entities
const userAdapter = createEntityAdapter<CurrentUser>();

// Initialize the state using the adapter
const initialState = userAdapter.getInitialState();

// Create an API slice for authentication
export const authApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credential: { email: string; password: string }) => ({
                url: "auth/login",
                method: "POST",
                body: { ...credential },
            }),
            // Invalidate the 'Auth' tag when this mutation is executed
            invalidatesTags: ["Auth"],
        }),
        getMe: builder.query({
            query: () => `auth/me`,
            // Transform the response and add the user data to the state using the adapter
            transformResponse: (response: CurrentUser) => {
                return userAdapter.setOne(initialState, response);
            },
            // Provide the 'Auth' tag when this query is executed
            providesTags: (
                result,
                error,
                arg
            ): { id: string | number; type: "Auth" }[] =>
                [...result!.ids.map((id) => ({ type: "Auth", id }))] as {
                    id: string | number;
                    type: "Auth";
                }[],
        }),
        reAuth: builder.mutation({
            query: (token: string) => ({
                url: "auth/re-auth",
                method: "POST",
                body: token,
            }),
            invalidatesTags: ["Auth"],
        }),
        resetCountUnseenWords: builder.mutation({
            query: (id: string) => ({
                url: `users/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["Auth"],
        }),

        resetDb : builder.mutation({
            query: (id : string) => ({
                url: `reset-user-test/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["Auth"],
        })
    }),
});

// Export mutation and query hooks generated by the API slice
export const {
    useLoginMutation,
    useGetMeQuery,
    useReAuthMutation,
    useResetCountUnseenWordsMutation,
    useResetDbMutation
} = authApiSlice;

// returns the query result object
export const selectGetMe = authApiSlice.endpoints.getMe.select("");

// Creates memoized selector
export const authSelector = createSelector(selectGetMe, (me) => me.data);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const { selectAll: selectMe } = userAdapter.getSelectors(
    (state) => authSelector(state as any) ?? initialState
);
