import { FC, useMemo } from "react";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import "./GameIntro.scss";
import useGetCurrentUser from "hook/useGetCurrentUser";
import detectiveStarter from "assets/img/common/Detective/detective-starter.svg";
import detectiveSuspicious from "assets/img/common/Detective/detective-suspicious-alone.svg";
import getClasses from "utils/getClasses";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import monkey from "assets/img/parrot/elements/monkey-welcome.svg";
import plant from "assets/img/parrot/elements/intro-plant.svg";
import { useEffectAudio } from "hook/useEffectAudio";
import { ActionCard } from "components/ActionCard/ActionCard";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { getTransitionClass } from "utils/getTransitionClass";
import { CardIconEnum } from "models/CardIconEnum";

export const GameIntro: FC<Partial<ChapterSceneFC>> = ({ action, out, page = 1, onBack }) => {
  const { currentUser } = useGetCurrentUser();
  const { volumeVoice } = useEffectAudio();

  const contents: SceneDataT = useMemo(
    () => [
      {
        title: `Benvenut${currentUser?.gender === "MALE" ? "o" : "a"} a Eufauna ${
          currentUser?.name || ""
        }`,
        text: [
          "Eufauna è un luogo fantastico in cui umani e animali convivono felici e rispettano l’ambiente che li circonda.",
        ],
        images: {
          felix: detectiveStarter,
        },
      },
      {
        title: `Benvenut${currentUser?.gender === "MALE" ? "o" : "a"} a Eufauna ${
          currentUser?.name || ""
        }`,
        text: [
          "Alcuni eventi però stanno mettendo a rischio la serenità dei suoi abitanti e la bellezza dell’intero continente.",
        ],
        images: {
          felix: detectiveSuspicious,
        },
      },
    ],
    [currentUser?.gender, currentUser?.name]
  );

  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () =>
      Boolean(volumeVoice) && (
        <SpeechSynthesis
          notVisible
          texts={[`${page === 1 ? contents[page - 1].title : ""}`, ...contents[page - 1].text]}
          start={Boolean(page)}
          setFinished={() => setIsFinished(true)}
        />
      ),
    [volumeVoice, page, contents, setIsFinished]
  );

  return currentUser ?
      <div
        className={`game-intro-container fade-in-image ${getClasses({
          "fade-out-image": out,
        })}`}
      >
        {Array.from({ length: contents.length }).map((item, index) => {
          const {
            title,
            text,
            images: { felix },
          } = contents[index];
          return (
            page === index + 1 && (
              <div
                className={`page-container page-${index + 1} ${getTransitionClass([
                  page,
                  index + 1,
                ])}`}
                key={`${item}-${index}`}
              >
                <div className="card-center">
                  <ActionCard
                    iconImg={CardIconEnum.LENS}
                    data-cy="test-action-card"
                    title={title}
                    buttonText={page === 1 ? "Avanti" : "Inizia"}
                    text={text}
                    action={action}
                    backArrow={page === 2}
                    goBack={onBack}
                  />
                </div>
                {renderSpeech}
                <img className={`img-felix-${index + 1}`} src={felix} alt="Felix" />
              </div>
            )
          );
        })}
        <img className="img-monkey" src={monkey} alt="monkey" />
        <img className="img-plant" src={plant} alt="plant" />
      </div>
    : null;
};
