import "./LoginPage.scss";
import { Controller, useForm } from "react-hook-form";
import { useLoginMutation } from "../../../store/features/authSlice";
import { GenericButton } from "../../../components/GenericButton/GenericButton";
import { LoginForm, LoginResponse } from "models/AuthCredentials";
import { useDispatch } from "react-redux";
import { setAuthCredentials } from "store/slices/tokenSlice";
import { setCurrentScene } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import usePortraitMode from "hook/usePortraitMode";
import { SceneEnum } from "models/EnumScene";
/**
 * LoginPage Component: Represents the login page.
 *
 * @returns {JSX.Element} - The LoginPage component.
 */
export const LoginPage = () => {
    const [login] = useLoginMutation();
    const dispatch = useDispatch();
    const { isPortrait } = usePortraitMode();
    const {
        control,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });
    // Function to handle form submission.
    const onSubmit = async (formData: LoginForm) => {
        try {
            const { data } = (await login({
                email: formData.email,
                password: formData.password,
            })) as LoginResponse;
            if (data) {
                dispatch(setAuthCredentials(data));
                dispatch(setCurrentScene(SceneEnum.FORWARD_ONE));
            } else if (errors) {
                setError("email", {
                    message: "Email o password non validi.",
                });
                setError("password", {
                    message: "Email o password non validi.",
                });
            }
        } catch (error) {
            console.error("Login failed", error);
        }
    };
    return (
        <>
            <div className="container-fluid h-100 fade-in-image">
                <div className="row h-100">
                    <div
                        className={`${getClasses({
                            "col-6": !isPortrait,
                            "img-login-portrait": isPortrait,
                        })} cont-login-img`}
                    ></div>
                    <div
                        className={`container-login ${getClasses({
                            "col-6": !isPortrait,
                        })}`}
                    >
                        <div className="wrapper-login">
                            <h3 className="login-form-title">
                                Accedi per giocare
                            </h3>
                            <form className="form-container">
                                <div className="container-input">
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{
                                            required: true,
                                            pattern:
                                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i,
                                        }}
                                        render={({ field }) => (
                                            <label className="w-100">
                                                <span
                                                    className={`login-form-label ${getClasses(
                                                        {
                                                            "error-color":
                                                                Boolean(
                                                                    errors.email
                                                                ),
                                                        }
                                                    )}`}
                                                >
                                                    Email
                                                </span>
                                                <input
                                                    type="email"
                                                    data-cy="email"
                                                    className={`form-control login-control ${getClasses(
                                                        {
                                                            "errors-control":
                                                                Boolean(
                                                                    errors.email
                                                                ),
                                                        }
                                                    )} 
                                                `}
                                                    {...field}
                                                    placeholder="Inserire email qui"
                                                    aria-label="email"
                                                    aria-describedby="basic-addon1"
                                                />
                                            </label>
                                        )}
                                    />
                                    {errors.email && (
                                        <p className="error-text">
                                            {errors.email?.message ||
                                                "Il campo inserito non è corretto."}
                                        </p>
                                    )}
                                </div>
                                <div className="container-input">
                                    <Controller
                                        name="password"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <label className="w-100 margin-top-login ">
                                                <span
                                                    className={`login-form-label ${getClasses(
                                                        {
                                                            "error-color":
                                                                Boolean(
                                                                    errors.email
                                                                ),
                                                        }
                                                    )}`}
                                                >
                                                    Password
                                                </span>
                                                <input
                                                    data-cy="password"
                                                    className={`form-control login-control ${getClasses(
                                                        {
                                                            "errors-control":
                                                                Boolean(
                                                                    errors.password
                                                                ),
                                                        }
                                                    )} `}
                                                    {...field}
                                                    type="password"
                                                    placeholder="Password"
                                                    aria-label="password"
                                                    aria-describedby="basic-addon1"
                                                />
                                            </label>
                                        )}
                                    />
                                    {errors.password && (
                                        <p className="error-text">
                                            {errors.password.message ||
                                                "Il campo inserito non è corretto."}
                                        </p>
                                    )}
                                </div>
                                <div className="form-check margin-top-login">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                    />
                                    <label className="form-check-label">
                                        Ricordami
                                    </label>
                                </div>
                                <GenericButton
                                    buttonType="submit"
                                    size="large"
                                    type="button"
                                    isFullWidth
                                    color="theme-purple"
                                    text="login"
                                    action={handleSubmit(onSubmit)}
                                />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};