import React, { FC, useEffect, useRef, useState } from "react";
import "./SpeechSynthesis.scss";
import { RoundButton } from "components/RoundButton/RoundButton";
import { SpeechSynthesisFC } from "models/SpeechSynthesisFC";
import { useCallback } from "react";
import { useEffectAudio } from "hook/useEffectAudio";
/**
 * speech synthesis
 * @param {string} prop.texts texts to add to speech synthesis
 * @param {boolean} prop.notVisible to hidden component
 * @returns {JSX.Element}
 */

const synth = window.speechSynthesis;
export const speaker = new SpeechSynthesisUtterance();
const isWindows = navigator.userAgent.toLowerCase().includes("win");
const isMacOS = navigator.userAgent.toLowerCase().includes("mac");

export const SpeechSynthesis: FC<Partial<SpeechSynthesisFC>> = ({
  texts = [""],
  start,
  notVisible,
  setFinished,
}) => {
  const [repeat, setRepeat] = useState(false);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentText, setCurrentText] = useState(texts[0]);
  const [isFinished, setIsFinished] = useState(false);
  const [utterance, setUtterance] = useState<SpeechSynthesisUtterance | null>(null);
  const { volumeVoice, setSpeaking, toggleVolumeMusic, volumeMusic, isPaused, setIsPaused } =
    useEffectAudio();
  const savedVolumeMusic = useRef<number | null>(null);
  /**
   * to use google speaker
   */

  const getVoice = useCallback(() => {
    const voices = synth.getVoices();
    return voices.find(({ name }) => name === "Google italiano");
  }, []);

  useEffect(() => {
    /**
     * SpeechSynthesisUtterance config
     * valume change speaker volume
     * rate change speaker speed
     * pitch change speaker tone
     */
    const utteranceOptions = {
      text: currentText,
      voice: getVoice(),
      volume: volumeVoice,
      rate:
        isWindows ? 1.2
        : isMacOS ? 1.1
        : 1.1,
      pitch: 1.1,
    };

    /**
     * create new SpeechSynthesisUtterance object
     */
    speaker && setSpeaking(true);
    Object.assign(speaker, utteranceOptions);
    setUtterance(speaker);

    if (!volumeVoice) {
      setSpeaking(false);
      synth.cancel();
      setIsPaused(true);
    } else if (!isPaused) {
      synth.speak(speaker);
      speaker.onend = () => {
        setSpeaking(false);
        setRepeat(false);
        setCurrentTextIndex((prevIndex) => {
          if (prevIndex === texts.length - 1) {
            setIsFinished(true);
            synth.cancel();
          }
          return prevIndex + 1;
        });
      };

      return () => {
        synth.cancel();
      };
    }
  }, [currentText, getVoice, isPaused, setSpeaking, texts.length, volumeVoice, setIsPaused]);

  /**
   * play audio function
   * @returns void
   */
  const repeatVoice = useCallback(() => {
    const currentText = texts && texts[currentTextIndex];
    if (utterance && currentText && volumeVoice) {
      synth.cancel();
      setCurrentText(currentText);
      synth.speak(utterance);
      setRepeat(false);
    }
  }, [texts, currentTextIndex, utterance, volumeVoice]);

  useEffect(() => {
    ((currentTextIndex > 0 && currentTextIndex < texts.length) || repeat) && repeatVoice();

    if (start && volumeVoice && !isPaused) {
      repeatVoice();
    }
  }, [isFinished, volumeVoice, currentTextIndex, isPaused, repeat, repeatVoice, start, texts]);

  const volumeMusicControl = useCallback(() => {
    if (volumeMusic !== 0) {
      if (volumeVoice && !isFinished && !isPaused && savedVolumeMusic.current === null) {
        savedVolumeMusic.current = volumeMusic;
        toggleVolumeMusic(0.2);
      } else if ((isFinished || isPaused) && savedVolumeMusic.current !== null) {
        toggleVolumeMusic(savedVolumeMusic.current);
        savedVolumeMusic.current = null;
      }
    }
  }, [isFinished, isPaused, toggleVolumeMusic, volumeMusic, volumeVoice]);

  useEffect(() => {
    isFinished && setFinished && setFinished();
  }, [isFinished, setFinished]);

  useEffect(() => {
    volumeMusicControl();
  }, [volumeMusicControl]);

  return (
    <div data-cy="test-speech">
      {notVisible || (
        <RoundButton
          type="replay"
          action={() => {
            setCurrentTextIndex(0);
            setRepeat(true);
          }}
        />
      )}
    </div>
  );
};
