import React from "react";
import "./VolumeSlider.scss";

interface VolumeSliderProps {
    volume: number;
    toggleVolume: (volume: number) => void;
    openSlider?: boolean;
    color: "violet" | "yellow";
}

export const VolumeSlider: React.FC<VolumeSliderProps> = React.memo(
    ({ volume, toggleVolume, openSlider, color }) => {
        const changeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
            const tempSliderValue = event.currentTarget.valueAsNumber;
            toggleVolume(tempSliderValue);
        };

        const styleBackground = `linear-gradient(to right, ${
            color === "violet" ? "#9747ff" : "#ffca24"
        } ${volume * 100}%, #eeeff1 ${volume * 100}%)`;

        return openSlider ? (
            <div
                data-cy="volume-slider"
                className={`volume-slider-container ${color}`}
            >
                <input
                    className="range-input"
                    placeholder="0"
                    type="range"
                    min={0}
                    max={1}
                    step={0.1}
                    value={volume}
                    onChange={changeValue}
                    style={{
                        background: styleBackground,
                    }}
                />
            </div>
        ) : null;
    }
);
