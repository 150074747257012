import { useEffect, useState } from "react";

export const useComponentVisibles = (
    initial: boolean,
    refs: React.MutableRefObject<(HTMLDivElement | null)[]>,
    callback?: () => void
) => {
    const [isComponentVisible, setIsComponentVisible] = useState<boolean[]>(
        Array(refs.current.length).fill(initial)
    );
    const handleClickOutside = (event: MouseEvent) => {
        refs.current.forEach((ref, index) => {
            if (ref && !ref.contains(event.target as Node)) {
                setIsComponentVisible((prevState) => {
                    const newState = [...prevState];
                    newState[index] = false;
                    callback && callback();
                    return newState;
                });
            }
        });
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    const setComponentVisible = (index: number) => {
        setIsComponentVisible((prevState) => {
            const newState = [...prevState];
            newState[index] = !prevState[index];
            return newState;
        });
    };

    return { isComponentVisible, setComponentVisible };
};
