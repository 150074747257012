import { Outlet } from "react-router-dom";

/**
 * GamesContainer Component: Represents a container for displaying game content.
 *
 * @returns {JSX.Element} - The GamesContainer component.
 */

export const GamesContainer = () => {
    return (
        <>
            <Outlet />
        </>
    );
};
