import { FC, useEffect, useMemo } from "react";
import "./ToucanFwdIntroSceneTwo.scss";
import getClasses from "utils/getClasses";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import box from "assets/img/toucan/elements/box-with-musical-notes.svg";
import detectiveIndicate from "assets/img/common/Detective/detective-alone-listen-indicate.svg";
import detectiveWink from "assets/img/common/Detective/detective-alone-listen-wink.svg";
import { getTransitionClass } from "utils/getTransitionClass";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

/**
 * ToucanFwdIntroSceneTwo Component
 *
 * This component represents a second chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const contents: SceneDataT = [
  {
    title: "",
    text: [],
    audio: {
      littleToucan: "src/assets/audio/toucan/TUCANO_BD.m4a",
    },
    images: {},
  },
  {
    title: "Detective Felix",
    text: [
      "Hey, ma quello sembra proprio il verso di Tuco! Forse è rimasto intrappolato tra tutti quegli oggetti. Mi aiutate a trovarlo?",
      "Dobbiamo liberarlo!",
    ],
    images: {
      felix: detectiveIndicate,
    },
  },
  {
    title: "Detective Felix",
    text: [
      "Per tirare fuori Tuco, facciamo un po’ di ordine tra gli oggetti nella scatola.",
      "Ascolterai una serie di parole che dovrai memorizzare. Al termine della serie ti verranno mostrate delle immagini. Tocca quelle che rappresentano le parole che hai ascoltato, nell’ordine corretto.",
    ],
    images: {
      felix: detectiveWink,
    },
  },
];

export const ToucanFwdIntroSceneTwo: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { volumeVoice } = useEffectAudio();
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const littleToucanAudio = useMemo(() => {
    return new Howl({
      src: require("../../../../../../assets/audio/toucan/TUCANO_BD.m4a"),
      volume: volumeVoice,
    });
  }, [volumeVoice]);

  useEffect(() => {
    if (page === 1) {
      littleToucanAudio.play();
    }
    return () => {
      littleToucanAudio.stop();
    };
  });

  const renderSpeech = useMemo(
    () =>
      Boolean(volumeVoice) &&
      page && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          start={Boolean(page)}
          setFinished={() => setIsFinished(true)}
        />
      ),
    [page, setIsFinished, volumeVoice]
  );

  return (
    <div
      className={`toucan-intro-scene-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img className="img-box" src={box} alt="box" />

      <div className="content">
        {Array.from({ length: contents.length }).map((item, index) => {
          const {
            title,
            text,
            images: { felix },
          } = contents[index];
          return (
            page === index + 1 && (
              <div
                className={`page-container ${getTransitionClass([page, index + 1])}`}
                key={`${item}-${index}`}
              >
                {[2, 3].includes(page) && (
                  <>
                    <div className="container-gradient fade-in-image" />
                    <img className="img-detective" src={felix} alt="detective" />
                    <div className={`container-comic-${index + 1}`}>
                      <SimpleCard title={title} text={text} />
                    </div>
                    {renderSpeech}
                  </>
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
