import { useDeviceSize } from "hook/useDeviceSize";
import "./PortraitAlert.scss";
import usePortraitMode from "hook/usePortraitMode";
type PortraitAlertProps = {
    children?: React.ReactElement | null;
};

const PortraitAlert = ({ children }: PortraitAlertProps) => {
    const { isPortrait } = usePortraitMode();
    const { isMobile } = useDeviceSize();

    return isPortrait || isMobile ? (
        <div className="portrait-alert fade-in-image">
            <div className="portrait-alert-content">
                <i
                    className={`portrait-alert-icon ${
                        isPortrait
                            ? "icon-screen-rotation"
                            : isMobile && "icon-game"
                    }`}
                ></i>

                <h3 className="title-container__text">
                    {isPortrait && "Ruota per iniziare a giocare"}
                    {isMobile && "Accedi da un tablet per iniziare a giocare"}
                </h3>
            </div>
        </div>
    ) : (
        <>{children}</>
    );
};

export default PortraitAlert;
