import freeParrots from "assets/img/parrot/background/parrot-end-four.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC } from "react";
import getClasses from "utils/getClasses";
import "./ParrotEndSceneThree.scss";
/**
 * ParrotEndSceneThree Component
 *
 * This component represents the fourth part of the end of a chapter in the story.
 * It displays a fading-in image with various elements such as trees, plants, parrots, and more.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The rendered component.
 */

const parrotEndSceneThreeData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
];

export const ParrotEndSceneThree: FC<Partial<ChapterSceneFC>> = ({ out, page, action }) => {
  const contents = parrotEndSceneThreeData;
  useSceneAutoPlay({ page: page!, contents, action: action! });

  return (
    <div
      className={`parrot-end-scene-three fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img
        className="background"
        src={freeParrots}
        alt="Happy and free parrots fly in the jungle"
      />
    </div>
  );
};
