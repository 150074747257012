import alone from "assets/img/common/elements/alone.svg";
import brioInTheBackpack from "assets/img/toucan/elements/backpack-with-toucan.svg";
import listenFelix from "assets/img/common/Detective/listen-felix.svg";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC, useMemo } from "react";
import getClasses from "utils/getClasses";
import { getTransitionClass } from "utils/getTransitionClass";
import "./ToucanSpinoffSceneTwo.scss";

/**
 * ToucanSpinoffSceneTwo Component
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of the scene inside component
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const toucanSpinoffSceneTwoData: SceneDataT = [
  {
    title: "Brio",
    text: [
      "Mentre giocavo nella foresta qualcuno mi ha acciuffato e mi ha messo qui dentro. Mi aiutate ad uscire? Non riesco a muovermi!",
    ],
    images: {
      brio: brioInTheBackpack,
    },
  },
  {
    title: "Detective Felix",
    text: [
      "Manca poco, Brio è quasi libero.",
      "Adesso ascolta le parole e poi tocca le immagini corrispondenti nell' ordine inverso, dall'ultima alla prima.",
    ],
    images: {
      brio: brioInTheBackpack,
      felix: listenFelix,
    },
  },
];

export const ToucanSpinoffSceneTwo: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { volumeVoice } = useEffectAudio();
  const contents = toucanSpinoffSceneTwoData;
  const [
    {
      images: { brio },
    },
  ] = contents;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const renderSpeech = useMemo(
    () =>
      Boolean(volumeVoice) &&
      page && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          start={Boolean(page)}
          setFinished={() => setIsFinished(true)}
        />
      ),
    [contents, page, volumeVoice, setIsFinished]
  );

  return (
    <div
      className={`toucan-spinoff-scene-two fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className="content">
        <img className="toucan-figure" src={brio} alt="Brio is hiding in a backpack" />
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const { title, text, images } = contents[pageDiv];
          return (
            page === pageDiv + 1 && (
              <div
                key={`scene-page-${page}`}
                className={`page-container ${getTransitionClass([page, pageDiv + 1])}`}
              >
                {page === 2 && <div className="background-overlay" />}
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                </div>
                {renderSpeech}
                {pageDiv + 1 === 2 && (
                  <>
                    <img className="alone" src={alone} alt="alone behind Felix" />
                    <img
                      className="felix-figure"
                      src={images.felix}
                      alt="Felix need your attention"
                    />
                  </>
                )}
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
