import { RoundButton } from "components/RoundButton/RoundButton";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { Timer } from "components/Timer/Timer";
import useEditSessionGame from "hook/useEditSessionGame";
import { ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import { EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { WrapperGameFC } from "models/WrapperGameFC";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeCurrentSession } from "store/slices/currentSessionSlice";
import {
  modeGameSelector,
  setCurrentScene,
  setGame,
  setIsFirstGame,
  setModeGame,
} from "store/slices/modeGameSlice";
import "./WrapperGame.scss";

/**
 * WrapperGame Component: Represents a wrapper for a game interface.
 *
 * @param {Object} props - The component's properties.
 * @param {ReactNode} props.children - The child components to be displayed within the wrapper.
 * @param {ReactNode} props.timer - The timer game.
 * @returns {JSX.Element} - The WrapperGame component.
 */
export const WrapperGame: FC<WrapperGameFC> = React.memo(
  ({ children, timerGame, title, description }) => {
    const { updateCurrentSession } = useEditSessionGame();
    const modeGame = useSelector(modeGameSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [startSpeech, setStartSpeech] = useState(false);
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
      isFinished &&
        dispatch(
          setModeGame({
            type: TypeGameEnum.SPEECH_GAME_TUTORIAL,
            mode: modeGame.mode,
          })
        );
    }, [dispatch, isFinished, modeGame.mode]);

    const timeUp = async () => {
      if (
        modeGame.mode === ModeGameEnum.FORWARD_GAME &&
        modeGame.type === TypeGameEnum.PLAY_GAME
      ) {
        dispatch(
          setModeGame({
            type: TypeGameEnum.START_MODAL_DEMO,
            mode: ModeGameEnum.REVERSE_GAME,
          })
        );
        dispatch(setIsFirstGame(false));
        dispatch(
          setCurrentScene(
            modeGame.currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
              modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ?
                SceneEnum.SPINOFF_FIRST_TWO
              : SceneEnum.SPINOFF_SECOND_TWO
            : SceneEnum.REVERSE_STARTED
          )
        );
        updateCurrentSession({ isReverseGame: true, completed: false });
        navigate(
          `/${EnumPath.GAMES}/${modeGame.game}/${EnumPath.SCENE}/${
            (
              modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ||
              modeGame.currentScene.includes(SceneEnum.SPINOFF_SECOND)
            ) ?
              SceneEnum.SPINOFF_FIRST_TWO
            : SceneEnum.REVERSE_STARTED
          }`
        );
      }
      if (
        modeGame.mode === ModeGameEnum.REVERSE_GAME &&
        modeGame.type === TypeGameEnum.PLAY_GAME
      ) {
        dispatch(
          setModeGame({
            type: TypeGameEnum.START_MODAL_DEMO,
            mode: ModeGameEnum.FORWARD_GAME,
          })
        );
        dispatch(
          setCurrentScene(
            modeGame.currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
              modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ?
                SceneEnum.SPINOFF_FIRST_THREE
              : SceneEnum.SPINOFF_SECOND_THREE
            : SceneEnum.END_ONE
          )
        );
        await updateCurrentSession({ isReverseGame: false, completed: true });
        navigate(
          `/${EnumPath.GAMES}/${modeGame.game}/${EnumPath.SCENE}/${
            (
              modeGame.currentScene.includes(SceneEnum.SPINOFF_FIRST) ||
              modeGame.currentScene.includes(SceneEnum.SPINOFF_SECOND)
            ) ?
              SceneEnum.SPINOFF_FIRST_THREE
            : SceneEnum.END_ONE
          }`
        );
      }
    };

    const goBack = () => {
      dispatch(
        setModeGame({
          type: TypeGameEnum.START_MODAL_DEMO,
          mode: ModeGameEnum.FORWARD_GAME,
        })
      );
      dispatch(removeCurrentSession());
      navigate(`/${EnumPath.DASHBOARD}`);
      dispatch(setGame(""));
    };

    useEffect(() => {
      modeGame.type === TypeGameEnum.TRAINING_TUTORIAL && setStartSpeech(true);
      (modeGame.type === TypeGameEnum.PLAY_GAME ||
        modeGame.type === TypeGameEnum.SPEECH_GAME) &&
        setStartSpeech(false);
    }, [modeGame]);

    return (
      <div className="container-wrapper" data-cy="test-wrapper-game">
        {modeGame.type !== TypeGameEnum.START_MODAL_DEMO &&
          modeGame.type !== TypeGameEnum.END_MODAL_GAME &&
          modeGame.type !== TypeGameEnum.START_MODAL_GAME && (
            <div className="container-wrapper-top">
              <div className="label-game-cont">
                <SpeechSynthesis
                  texts={[`${title}.${description}`]}
                  start={startSpeech}
                  setFinished={() => setIsFinished(true)}
                />
                <div className="label-game">
                  <h2 className="label-game-title">{title}</h2>
                  <p className="label-game-paragraph">{description}</p>
                </div>
              </div>
              <div className="timer-close-container">
                {modeGame.game !== EnumPath.SLOTH_GAME &&
                  (modeGame.type === TypeGameEnum.PLAY_GAME ||
                    modeGame.type === TypeGameEnum.SPEECH_GAME) && (
                    <div className="timer-cont">
                      <Timer time={timerGame} timeUp={timeUp} />
                    </div>
                  )}
                <RoundButton
                  type="close"
                  action={goBack}
                  linkButton={`/${EnumPath.DASHBOARD}`}
                />
              </div>
            </div>
          )}
        <div className="container-wrapper-center">{children}</div>
      </div>
    );
  }
);
