import blinkingBrady from "assets/img/sloth/elements/prize-obtained-brady.svg";
import { ActionCard } from "components/ActionCard/ActionCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { SceneEnum } from "models/EnumScene";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { setCurrentScene } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import "./SlothEndSceneTwo.scss";
import { CardIconEnum } from "models/CardIconEnum";
/**
 * SlothEndSceneTwo Component
 *
 * This component represents the end of a chapter in the story.
 * It displays a fading-in image of a door.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {boolean} action - Invokes the function that changes component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const slothEndSceneTwoData: SceneDataT = [
  {
    title: "Ce l'hai fatta!",
    text: [
      "Il tuo aiuto è stato davvero prezioso. Spero che tu e il detective troviate presto il colpevole.",
      "Intanto ecco una parte del codice, di sicuro ti tornerà utile per le indagini. Torna a giocare domani per ottenerne altre!",
    ],
    images: {
      brady: blinkingBrady,
    },
  },
];

export const SlothEndSceneTwo: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const { volumeVoice } = useEffectAudio();
  const dispatch = useDispatch();
  const contents = slothEndSceneTwoData;

  const {
    title,
    text,
    images: { brady },
  } = contents[page - 1];

  const nextHandler = () => {
    dispatch(setCurrentScene(SceneEnum.MESSAGE_ONE));
    action && action();
  };

  return (
    <div
      className={`sloth-end-two-container fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      {Boolean(volumeVoice) && <SpeechSynthesis notVisible={true} texts={[title, ...text]} />}
      <div className="card-center">
        <ActionCard
          iconImg={CardIconEnum.BAG}
          title={title}
          isEndGame
          text={text}
          buttonText="Vai al messaggio"
          action={nextHandler}
        />
      </div>
      <img className="brady-figure" src={brady} alt="blinking brady" />
    </div>
  );
};
