import { GameCard } from "components/GameCard/GameCard";
import { GenericButton } from "components/GenericButton/GenericButton";
import { Notification } from "components/Notification/Notification";
import { RoundButton } from "components/RoundButton/RoundButton";
import { useEffectAudio } from "hook/useEffectAudio";
import useGetSessionGameChapter from "hook/useGetSessionGameChapter";
import { TypeGameEnum } from "models/EnumModeGame";
import { EnumPath } from "models/EnumPath";
import { SceneEnum } from "models/EnumScene";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectMe, useGetMeQuery, useResetDbMutation } from "store/features/authSlice";
import {
  modeGameSelector,
  setCurrentScene,
  setGame,
  setModeGame,
} from "store/slices/modeGameSlice";
import { removeAuthCredentials } from "store/slices/tokenSlice";
import { persistor } from "store/store";
import "./GameChapters.scss";

/**
 * The Game Page component displays a collection of game cards.
 * It includes navigation buttons and information about each game.
 *
 * @returns {JSX.Element} The rendered GamePage component.
 */

export const GameChapters = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const modeGame = useSelector(modeGameSelector);
  const { cardUpdate, sessions } = useGetSessionGameChapter();
  const { data, refetch } = useGetMeQuery("");
  const currentUser = useSelector(selectMe);
  const { toggleCurrentMusic } = useEffectAudio();

  const [resetDB] = useResetDbMutation();

  const logout = () => {
    dispatch(removeAuthCredentials());
    persistor.purge();
    navigate(`/${EnumPath.LOGIN}`);
  };

  useEffect(() => {
    const fetchData = async () => await refetch();
    fetchData();
  }, [data, refetch]);

  useEffect(() => {
    dispatch(setGame(""));
    dispatch(
      setModeGame({
        type: TypeGameEnum.START_MODAL_DEMO,
        mode: modeGame.mode,
      })
    );
  }, [dispatch, modeGame.mode]);

  useEffect(() => {
    toggleCurrentMusic("music", "music-menu");
  }, [toggleCurrentMusic]);

  return (
    <div data-cy="game-chapters" className="game-cards fade-in-image">
      <div data-cy="game-chapters-header" className="title-container">
        <div className="title-container-text">
          <h1 className="title">I misteri della foresta</h1>
        </div>
        <div className="title-container-button">
          <GenericButton text="RESET DB" action={() => resetDB(currentUser[0]._id)} />
          {/*TODO momentary button to go (dispatch) to a specific test scene */}
          {/* <GenericButton
            text="go"
            action={() => {
              dispatch(setGame(EnumPath.PARROT_GAME));
              dispatch(setCurrentScene(SceneEnum.END_FOUR));
              navigate(
                `/${EnumPath.GAMES}/${EnumPath.PARROT_GAME}/${EnumPath.SCENE}/${SceneEnum.END_FOUR}`
              );
            }}
          /> */}
        </div>
        <div className="container-button-message">
          <RoundButton
            type="message"
            action={() => dispatch(setCurrentScene(SceneEnum.MESSAGE_ONE))}
            linkButton={`/${EnumPath.GAMES}/${EnumPath.MESSAGE}/${SceneEnum.MESSAGE_ONE}`}
          />
          {data && Object.values(data.entities)[0]?.countUnseenWords ?
            <Notification
              value={
                (Object.values(data.entities)[0] &&
                  Object.values(data.entities)[0]?.countUnseenWords) ||
                0
              }
            />
          : null}
          <RoundButton type="logout" action={logout} />
        </div>
      </div>
      <div data-cy="game-chapters-cards" className="cards-container">
        {cardUpdate?.map((card, index) => {
          return (
            <div key={index} className="card-item">
              <GameCard
                title={card.title}
                imagePath={card.imagePath}
                componentToRender={card.componentToRender}
                initialProgress={card.initialProgress}
                gameName={card.gameName}
                resume={sessions?.find(
                  (elem) =>
                    elem.gameName === card.gameName &&
                    !elem.gameInfo.completed &&
                    !modeGame.isFirstGame
                )}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
