import { EnumSlothGame } from "models/EnumSlothGame";

export const thirdGameStimuli: Record<number, (keyof typeof EnumSlothGame)[]> = {
    1: [
        "FISH",
        "LION",
        "ELEPHANT",
        "BUTTERFLY",
        "BEE",
        "CRAB",
        "DOG",
        "WHALE",
        "PARROT",
        "TURTLE",
    ],
    2: [
        "BALL",
        "TEDDY_BEAR",
        "CARDS",
        "DICES",
        "DOLL",
        "TOY_CAR",
        "PYRAMID_OF_CIRCLES",
        "TOY_TRAIN",
        "ROCKING_HORSE",
        "CHUTE",
    ],
    3: [
        "BANANA",
        "APPLE",
        "ORANGE",
        "PEAR",
        "KIWI",
        "CHERRY",
        "STRAWBERRY",
        "APRICOT",
        "WATERMELON",
        "COCONUT",
    ],
};
export const thirdGameStimuliTraining_N_Back: Record<number, (keyof typeof EnumSlothGame)[]> = {
    1: [
        "DOG",
        "ELEPHANT",
        "FISH",
        "PARROT",
        "PARROT",
        "WHALE",
        "LION",
        "BUTTERFLY",
        "BEE",
        "CRAB",
        "TURTLE",
    ],
    2: [
        "BALL",
        "TEDDY_BEAR",
        "TOY_TRAIN",
        "DOLL",
        "CARDS",
        "DOLL",
        "CHUTE",
        "DICES",
        "TOY_CAR",
        "PYRAMID_OF_CIRCLES",
        "ROCKING_HORSE",
    ],
    3: [
        "BANANA",
        "APPLE",
        "ORANGE",
        "PEAR",
        "KIWI",
        "CHERRY",
        "PEAR",
        "STRAWBERRY",
        "APRICOT",
        "WATERMELON",
        "COCONUT",
    ],
};
