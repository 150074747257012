import React, { FC, useEffect, useMemo, useState } from "react";
import "../MessagePage.scss";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SecretMessage } from "components/SecretMessage/SecretMessage";
import getClasses from "utils/getClasses";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import { EnumPath } from "models/EnumPath";
import { useDeviceSize } from "hook/useDeviceSize";
import backgroundParrot from "assets/img/parrot/background/background-reverse.svg";
import backgroundToucan from "assets/img/toucan/backgrounds/background-golden-sky.svg";
import backgroundPlant from "assets/img/parrot/elements/plant-end-game.svg";
import backgroundPalm from "assets/img/toucan/elements/palm-left.svg";
import alone from "assets/img/common/elements/alone.svg";
import adviceFelix from "assets/img/common/Detective/detective-advice.svg";
import { sentences } from "utils/wordsDiscovery";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { useEffectAudio } from "hook/useEffectAudio";

/**
 * MessagePageTwo Component
 *
 * This component represents second chapter of message scene.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @param {number} page - Indicates number of page in component.
 * @returns {JSX.Element} - The rendered component.
 */

const messageSceneTwoData: SceneDataT = [
  {
    title: "Detective Felix",
    text: [
      "È l’unico modo per trovare il colpevole e salvare la foresta.",
      "Ecco la prima parola!",
    ],
    images: {
      felix: adviceFelix,
    },
  },
  {
    title: "Il Messaggio dice: ",
    text: sentences.map(({ visible }) => visible).filter((word) => word),
    images: {},
  },
];

export const MessagePageTwo: FC<ChapterSceneFC> = React.memo(({ out, page, action }) => {
  const [trigger, setTrigger] = useState(false);
  const { game } = useSelector(modeGameSelector);
  const { isMd } = useDeviceSize();

  const contents = messageSceneTwoData;
  const {
    title,
    text,
    images: { felix },
  } = contents[0];
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action });
  const { volumeVoice } = useEffectAudio();

  useEffect(() => {
    page === 2 && setTrigger(true);
  }, [page]);

  const renderSpeech = useMemo(() => {
    if (Boolean(volumeVoice)) {
      return (
        <SpeechSynthesis
          notVisible
          start={page === 1}
          texts={[...text]}
          setFinished={() => {
            setIsFinished(true);
          }}
        />
      );
    }
  }, [page, setIsFinished, text, volumeVoice]);

  return (
    <div
      className={`container-message fade-in-image ${getClasses({
        "fade-out-image": out && page === 2,
      })}`}
      style={{
        backgroundImage: `url(${
          game === EnumPath.TOUCAN_GAME ? backgroundToucan : backgroundParrot
        })`,
      }}
    >
      {!isMd && (
        <img
          className={`background-plant ${game === EnumPath.TOUCAN_GAME ? "palm" : "plants"}`}
          src={`${game === EnumPath.TOUCAN_GAME ? backgroundPalm : backgroundPlant}`}
          alt="plant"
        />
      )}
      {page === 1 && <div className="background-overlay" />}
      <div className="container-parchment">
        <SecretMessage
          trigger={trigger}
          startSpeech={page === 2}
          setTrigger={setTrigger}
          setIsFinished={() => {
            page === 2 && setIsFinished(true);
          }}
        />
      </div>
      {page === 1 && (
        <div className="page-container fade-in-image">
          <img className="alone" src={alone} alt="alone behind felix" />
          <div className={`balloon-container balloon-${page}`}>
            <SimpleCard title={title} text={text} />
            {renderSpeech}
          </div>
          <img
            className={`felix-figure felix-${page}`}
            src={felix}
            alt="Felix pointing out at message"
          />
        </div>
      )}
    </div>
  );
});
