import { speaker } from "components/SpeechSynthesis/SpeechSynthesis";
import { SceneDataT } from "models/ChapterSceneFC";

export const getAutoPlayTime = (sceneContents: SceneDataT, page: number) => {
    const { title, text } = sceneContents[page - 1];
    if (title && text.length > 0) {
        const transitionTime = 1000;
        const wordMultiplier = 65;
        const pauseDuration = 1000; //500 is a debounce for mocked up extra words
        const totLength =
            title.length + text.reduce((length, string) => length + string.length, 0);
        const totalTime = totLength * wordMultiplier * speaker.rate + pauseDuration;
        return sceneContents.length === 1 ? totalTime + transitionTime : totalTime;
    } else {
        return 3000;
    }
};
