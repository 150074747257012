import { ModalFC } from "models/ModalFC";
import { FC } from "react";
import "./Modal.scss";

/**
 * Modal Component: A reusable modal dialog for displaying content.
 *
 * @param {Object} param - The props for configuring the modal.
 * @param {boolean} param.isOpen - Indicates whether the modal is open or closed.
 * @param {ReactNode} param.children - The content to be displayed inside the modal.
 * @param {() => void} param.onClose - A callback function to handle the modal's close action.
 * @returns {ReactElement|null} - The modal component or null if it's closed.
 */
export const Modal: FC<ModalFC> = ({ isOpen, children, onClose }) => {
    // If the modal is not open, return null to hide it
    if (!isOpen) return null;

    // Render the modal component with an overlay
    return (
        <div data-cy="test-modal" className="modal-overlay">
            {children}
        </div>
    );
};
