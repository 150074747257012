import felix from "assets/img/common/Detective/feedback.svg";
import backgroundParrot from "assets/img/parrot/background/background-reverse.svg";
import cloud from "assets/img/parrot/elements/cloud.svg";
import foregroundPlant from "assets/img/parrot/elements/plant-parrots.svg";
import backgroundToucan from "assets/img/toucan/backgrounds/background-golden-sky.svg";
import leftBranch from "assets/img/toucan/elements/message-branch-left.svg";
import foregroundFlowers from "assets/img/toucan/elements/message-foreground-flowers.svg";
import { ActionCard } from "components/ActionCard/ActionCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { ChapterSceneFC } from "models/ChapterSceneFC";
import { EnumPath } from "models/EnumPath";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { modeGameSelector, setIsFirstGame } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import "./MessageLastPage.scss";

/**
 * MessageLastPage Component
 *
 * This component represents final chapter of message scene.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The rendered component.
 */

export const MessageLastPage: FC<ChapterSceneFC> = ({ out }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const text = "Continua a giocare per scoprire le altre!";
  const { game } = useSelector(modeGameSelector);

  useEffect(() => {
    dispatch(setIsFirstGame(false));
  }, [dispatch]);

  return (
    <div
      className={`message-last-page fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
      style={{
        backgroundImage: `url(${
          game === EnumPath.TOUCAN_GAME ? backgroundToucan : backgroundParrot
        })`,
      }}
    >
      {game === EnumPath.TOUCAN_GAME ?
        <div key={`message-${game}-elements`} className="toucan-elements">
          <img
            className="left-branch"
            src={leftBranch}
            alt="a branch comes to the left on background"
          />
          <img
            src={foregroundFlowers}
            className="foreground-flower"
            alt="A Flower among green leaves on the foreground"
          />
        </div>
      : <div key={`message-${game}-elements`} className="parrot-elements">
          {[2, 1].map((item) => (
            <img
              key={`cloud-${item}`}
              className={`cloud cloud-${item} fade-in-image`}
              src={cloud}
              alt="cloud in the sky behind the card"
            />
          ))}
          <img
            className="foreground-plant"
            src={foregroundPlant}
            alt="Green plant on foreground"
          />
        </div>
      }
      <div className="card-center">
        <SpeechSynthesis notVisible texts={[text]} />
        <ActionCard
          title={text}
          buttonText="Ok"
          action={() => navigate(`/${EnumPath.DASHBOARD}`)}
        />
      </div>
      <img className="felix-figure" src={felix} alt="detective" />
    </div>
  );
};
