import { FC } from "react";
import { DemoGameFC } from "models/DemoGameFC";
import { RoundButton } from "components/RoundButton/RoundButton";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import "./DemoParrotGame.scss";
import { ActionCard } from "components/ActionCard/ActionCard";
import detectiveOk from "assets/img/common/Detective/ok.svg";
import detectiveIntroDemo from "assets/img/common/Detective/proof.svg";
import plant from "assets/img/parrot/elements/plant-parrots.svg";
import buildingPlant from "assets/img/parrot/elements/building-plant.svg";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import { SceneEnum } from "models/EnumScene";
import { EnumPath } from "models/EnumPath";
import detectiveSpinoff from "assets/img/common/Detective/let's-play-spinoff.svg";

/**
 * DemoParrotGame Component: Represents the introductory section of the Parrot Game demo.
 *
 * @param {Object} param - The props for configuring the introductory section.
 * @param {() => void} param.action - A callback function to be executed when a button is clicked.
 * @param {string} param.title - The title of the introductory section.
 * @param {boolean} [props.reverse=false] - A flag to determine if the layout should be reversed.
 * @param {string} props.type - The type of the game ('demo' or other).
 * @returns {ReactElement} - The DemoParrotGame component.
 */
export const DemoParrotGame: FC<Partial<DemoGameFC>> = ({
  action,
  title,
  reverse = false,
  type,
  link,
  startTutorial,
}) => {
  const { currentScene } = useSelector(modeGameSelector);

  return (
    <div
      data-cy="demo-parrot-game"
      className={`container-demo ${
        !reverse ? "container-demo-parrot" : "container-demo-parrot-reverse"
      }`}
    >
      <div className="button-top-close">
        <RoundButton type="close" linkButton={`/${EnumPath.DASHBOARD}`} />
      </div>
      <SpeechSynthesis texts={[`${title}`]} notVisible />
      <img
        className={`left-figure ${getClasses({
          "height-plant": !reverse,
        })}`}
        src={!reverse ? plant : buildingPlant}
        alt="plant"
        data-cy="demo-parrot-game-image"
      />

      {!currentScene.includes(SceneEnum.SPINOFF_SCENE) ?
        <img
          className="detective"
          src={type === "demo" ? detectiveIntroDemo : detectiveOk}
          alt="detective"
          data-cy="demo-parrot-game-figure"
        />
      : <img className="detective" src={detectiveSpinoff} alt="detective" />}

      <div className="card-center">
        <ActionCard
          title={title}
          buttonText="Ok"
          action={action}
          repeatTutorial={startTutorial}
          showTutorialButton={currentScene.includes(SceneEnum.SPINOFF_SCENE)}
        />
      </div>
    </div>
  );
};
