import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { SceneEnum } from "models/EnumScene";
import { FC } from "react";
import { useSelector } from "react-redux";
import { modeGameSelector } from "store/slices/modeGameSlice";
import getClasses from "utils/getClasses";
import "./ParrotSpinoffSceneThree.scss";

/**
 * ParrotSpinoffSceneThree Component
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const parrotSpinoffSceneThreeData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
];

export const ParrotSpinoffSceneThree: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const { currentScene } = useSelector(modeGameSelector);

  const contents = parrotSpinoffSceneThreeData;
  useSceneAutoPlay({ page, contents, action: action! });

  return (
    // Conditional class names based on the scene
    <div
      className={`container-spinoff-three fade-in-image ${
        currentScene?.includes(SceneEnum.SPINOFF_FIRST_THREE) ? "bg-first" : "bg-second"
      } ${getClasses({
        "fade-out-image": out,
      })}`}
    />
  );
};
