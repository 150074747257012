import foregroundPlant from "assets/img/toucan/elements/foreground-plant.svg";
import toucanMommy from "assets/img/toucan/elements/happy-mum-spinoff.svg";
import smallToucan from "assets/img/toucan/elements/small-toucan.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { FC } from "react";
import getClasses from "utils/getClasses";
import "./ToucanEndSceneOne.scss";
/**
 * ToucanEndSceneOne Component
 *
 * This component represents the end of a chapter in the story.
 * It displays a fading-in image of a door.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const toucanEndSceneOneData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {
      toucan: toucanMommy,
      littleToucan: smallToucan,
    },
  },
];

export const ToucanEndSceneOne: FC<Partial<ChapterSceneFC>> = ({ out, page = 1, action }) => {
  const contents = toucanEndSceneOneData;
  const { images } = contents[page - 1];
  useSceneAutoPlay({ page, contents, action: action! });

  return (
    <div
      className={`toucan-end-scene-one fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <img
        src={foregroundPlant}
        className="foreground-plant"
        alt="A plant with a flower in foreground"
      />
      <img src={images.toucan} className="toucan-figure mommy" alt="Happy toucan mommy" />
      <img
        src={images.littleToucan}
        className="toucan-figure little"
        alt="happy little toucan"
      />
    </div>
  );
};
