import { FC, useMemo } from "react";
import "./SlothFwdIntroSceneTwo.scss";
import getClasses from "utils/getClasses";
import { ChapterSceneFC, SceneDataT } from "models/ChapterSceneFC";
import { SimpleCard } from "components/SimpleCard/SimpleCard";
import { SpeechSynthesis } from "components/SpeechSynthesis/SpeechSynthesis";
import { useEffectAudio } from "hook/useEffectAudio";
import { getTransitionClass } from "utils/getTransitionClass";
import pointingFelix from "assets/img/common/Detective/indication-zoom-straight-eyes.svg";
import lookingFelix from "assets/img/common/Detective/see-zoom.svg";
import { useSceneAutoPlay } from "hook/useSceneAutoPlay";

/**
 * SlothFwdIntroSceneTwo Component
 *
 * This component represents a second chapter scene of forward flow.
 *
 * @param {boolean} out - Indicates whether to fade out the component.
 * @returns {JSX.Element} - The JSX element representing the component.
 */

const slothFwdIntroSceneTwoData: SceneDataT = [
  {
    title: "",
    text: [],
    images: {},
  },
  {
    title: "Detective Felix",
    text: [
      "Ogni volta che giocheremo, permetteremo a un nuovo albero di crescere molto più velocemente del solito.",
    ],
    images: {
      felix: pointingFelix,
    },
  },
  {
    title: "Detective Felix",
    text: [
      "Mettiamoci al lavoro!",
      "Solo ripopolando la foresta potremo tornare alla normalità.",
    ],
    images: {
      felix: lookingFelix,
    },
  },
];

export const SlothFwdIntroSceneTwo: FC<Partial<ChapterSceneFC>> = ({
  out,
  page = 1,
  action,
}) => {
  const contents = slothFwdIntroSceneTwoData;
  const { setIsFinished } = useSceneAutoPlay({ page, contents, action: action! });

  const { volumeVoice } = useEffectAudio();
  const renderSpeech = useMemo(
    () =>
      Boolean(volumeVoice) &&
      page &&
      page > 1 && (
        <SpeechSynthesis
          notVisible
          texts={[...contents[page - 1].text]}
          start={Boolean(page)}
          setFinished={() => setIsFinished(true)}
        />
      ),
    [contents, page, volumeVoice, setIsFinished]
  );

  return (
    <div
      className={`sloth-chapter-two-container fade-in-image ${getClasses({
        "fade-out-image": out,
      })}`}
    >
      <div className={`background ${page && page !== 1 ? "background-overlay" : ""}`}></div>
      <div className="content">
        {page !== 1 && <div className="alone"></div>}
        {Array.from({ length: contents.length }).map((_, pageDiv) => {
          const {
            title,
            text,
            images: { felix },
          } = contents[pageDiv];
          return (
            page > 1 &&
            page === pageDiv + 1 && (
              <div
                key={`scene-page-${pageDiv + 1}`}
                className={`page-container ${getTransitionClass([page, pageDiv + 1])}`}
              >
                <div className={`balloon-container balloon-${pageDiv + 1}`}>
                  <SimpleCard title={title} text={text} />
                  {renderSpeech}
                </div>
                <img className={`felix-figure felix-${pageDiv + 1}`} src={felix} alt="felix" />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};
