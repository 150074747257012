import {
    EnumCurrentSessionAnswer,
    EnumCurrentSessionMode,
} from "models/EnumCurrentSession";
import { ModeGameEnum, TypeGameEnum } from "models/EnumModeGame";
import {
    ValueMatrixFromEnum,
    EnumSecondGameSquare,
} from "models/EnumSecondGameSquare";
import { IUseGame } from "models/IUseGame";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLevelGame, setScore } from "store/slices/currentSessionSlice";
import {
    modeGameSelector,
    setModeGame,
    setTimerPaused,
} from "store/slices/modeGameSlice";
import { useEffectAudio } from "./useEffectAudio";

export const useToucanGame = ({ reverse }: IUseGame) => {
    const modeGame = useSelector(modeGameSelector);
    const { timerPaused } = useSelector(modeGameSelector);
    const { activePlayWin } = useEffectAudio();

    const dispatch = useDispatch();
    const keys = useMemo(
        () =>
            Object.keys(
                EnumSecondGameSquare
            ) as (keyof typeof EnumSecondGameSquare)[],
        []
    );

    const minLevel = 2;
    const [matrix, setMatrix] =
        useState<ValueMatrixFromEnum<typeof EnumSecondGameSquare>>();
    const [squares, setSquares] = useState(2);
    const [sequentialWords, setSequentialWords] = useState<
        EnumSecondGameSquare[]
    >([]);
    const [currentIndexTutorial, setCurrentIndexTutorial] = useState(0);
    let decrementCounter = useRef(0);
    let incrementCounter = useRef(0);
    const [isFlashing, setIsFlashing] = useState(false);
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const [sequentialWordsUser, setsequentialWordsUser] = useState<
        EnumSecondGameSquare[]
    >([]);

    const checkTutorial = useCallback(
        (e: keyof typeof EnumSecondGameSquare) => {
            const bool =
                sequentialWords[currentIndexTutorial] ===
                EnumSecondGameSquare[e];

            bool &&
                setCurrentIndexTutorial((prev) => prev + (reverse ? -1 : +1));
            return bool;
        },
        [currentIndexTutorial, reverse, sequentialWords]
    );

    const dispatchFn = useCallback(
        (
            prev: number,
            answerType: EnumCurrentSessionAnswer,
            incrementLevel?: boolean
        ) => {
            (prev + 1) % 2 === 0 &&
                prev !== 0 &&
                setSquares((prev) => {
                    const level = incrementLevel
                        ? prev + 1
                        : minLevel > prev - 1
                          ? minLevel
                          : prev - 1;

                    dispatch(
                        setLevelGame({
                            level,
                            mode: reverse
                                ? EnumCurrentSessionMode.MODE_REVERSE
                                : EnumCurrentSessionMode.MODE_FORWARD,
                        })
                    );
                    return level;
                });
            dispatch(
                setScore({
                    answer: answerType,
                    mode: reverse
                        ? EnumCurrentSessionMode.MODE_REVERSE
                        : EnumCurrentSessionMode.MODE_FORWARD,
                })
            );
            return prev + 1;
        },
        [dispatch, reverse]
    );

    const checkWinCondition = useCallback(() => {
        if (modeGame.type === TypeGameEnum.PLAYING_TUTORIAL)
            dispatch(
                setModeGame({
                    type: TypeGameEnum.START_MODAL_GAME, // Update the mode
                    mode: modeGame.mode, // Preserve the current mode
                })
            );
        else if (modeGame.type === TypeGameEnum.PLAY_GAME) {
            const areArraysEqual = (
                reverse ? sequentialWords.reverse() : sequentialWords
            ).every((value, index) => {
                return value === sequentialWordsUser[index];
            });

            if (areArraysEqual) {
                incrementCounter.current = dispatchFn(
                    incrementCounter.current,
                    EnumCurrentSessionAnswer.ANSWER_RIGHT,
                    areArraysEqual
                );
                activePlayWin();
            } else {
                decrementCounter.current = dispatchFn(
                    decrementCounter.current,
                    EnumCurrentSessionAnswer.ANSWER_WRONG,
                    areArraysEqual
                );
            }
        }
    }, [
        activePlayWin,
        dispatch,
        dispatchFn,
        modeGame.mode,
        modeGame.type,
        reverse,
        sequentialWords,
        sequentialWordsUser,
    ]);

    const getRandomWordsFromArray = useCallback(
        (words: EnumSecondGameSquare[], numberOfWords: number) => {
            const selectedWords = Array.from({
                length: numberOfWords,
            }).reduce((acc: EnumSecondGameSquare[], _) => {
                if (words.length > 0) {
                    const randomIndex = Math.floor(
                        Math.random() * words.length
                    );
                    const selectedWord = words.splice(randomIndex, 1)[0];
                    acc.push(selectedWord);
                }
                return acc;
            }, [] as EnumSecondGameSquare[]);
            setSequentialWords(selectedWords);
        },
        []
    );

    const createMatrix = useCallback(
        (rows: number, cols: number) => {
            if (
                modeGame.type === TypeGameEnum.TRAINING_TUTORIAL &&
                (modeGame.mode === ModeGameEnum.FORWARD_GAME ||
                    modeGame.mode === ModeGameEnum.REVERSE_GAME)
            ) {
                const tutorialMatrix: (keyof typeof EnumSecondGameSquare)[][] =
                    [
                        ["APPLE", "BRANCH", "DOG"],
                        ["HOME", "BREAD", "FISH"],
                        ["PIPE", "ROSE", "MOUSE"],
                        ["SUN", "POT", "CAKE"],
                    ];
                const tutorialSequence = [
                    EnumSecondGameSquare.APPLE,
                    EnumSecondGameSquare.SUN,
                    EnumSecondGameSquare.DOG,
                    EnumSecondGameSquare.POT,
                ];
                setSequentialWords(tutorialSequence);
                setMatrix(tutorialMatrix);
            } else {
                const _keys = [...keys];
                const selectedWords: EnumSecondGameSquare[] = [];
                const randomMatrix = Array.from({ length: rows }, () => {
                    return Array.from({ length: cols }, () => {
                        if (_keys.length === 0) {
                            _keys.push(..._keys);
                        }
                        const randomIndex = Math.floor(
                            Math.random() * _keys.length
                        );
                        const selectedKey = _keys.splice(randomIndex, 1)[0];
                        const selectedValue = EnumSecondGameSquare[selectedKey];
                        selectedWords.push(selectedValue);
                        return selectedKey;
                    });
                });
                getRandomWordsFromArray(selectedWords, squares);
                setMatrix(randomMatrix);
            }
        },
        [getRandomWordsFromArray, keys, modeGame.mode, modeGame.type, squares]
    );

    const resetMatrix = useCallback(() => {
        dispatch(setTimerPaused(true));
        setsequentialWordsUser([]);
        setSequentialWords([]);
        createMatrix(4, 3);
    }, [createMatrix, dispatch]);

    useEffect(() => {
        timerPaused && setIsFlashing(false);
        if (
            sequentialWordsUser &&
            !timerPaused &&
            !isFlashing &&
            modeGame.type === TypeGameEnum.PLAY_GAME
        ) {
            timerRef.current = setTimeout(() => {
                setIsFlashing(true);
            }, 5000);
            return () => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    timerRef.current = null;
                }
            };
        }
    }, [modeGame.type, sequentialWordsUser, timerPaused, isFlashing]);

    useEffect(() => {
        if (sequentialWords.length === sequentialWordsUser.length) {
            checkWinCondition();
            resetMatrix();
        }
    }, [
        checkWinCondition,
        resetMatrix,
        sequentialWords.length,
        sequentialWordsUser.length,
    ]);

    //Init game
    useEffect(() => {
        if (
            modeGame.type === TypeGameEnum.START_MODAL_GAME ||
            modeGame.type === TypeGameEnum.TRAINING_TUTORIAL
        ) {
            incrementCounter.current = 0;
            decrementCounter.current = 0;
            setCurrentIndexTutorial(reverse ? 3 : 0);
            setSquares(2);
            dispatch(setTimerPaused(true));
            createMatrix(4, 3);
        }
    }, [createMatrix, dispatch, modeGame.type, reverse]);
    return {
        matrix,
        sequentialWords,
        checkTutorial,
        setsequentialWordsUser,
        setIsFlashing,
        sequentialWordsUser,
        isFlashing,
    };
};
