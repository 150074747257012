import { GameCardMenu, gameCardMenu } from "data/gameCardMenu";
import { Session } from "models/Session";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectMe } from "store/features/authSlice";
import { useLazySessionActivedQuery } from "store/features/sessionSlice";

/**
 * useGetSessionGameChapter Custom Hook: get active sessions and edit menu cards.
 *
 * @returns {Object} - Card updated and sessions actived.
 */

const useGetSessionGameChapter = () => {
  const [sessions, setSessions] = useState<Session[] | undefined>();
  const [cardUpdate, setCardUpdate] = useState<GameCardMenu[]>();
  const currentUser = useSelector(selectMe);
  const [trigger, result] = useLazySessionActivedQuery();

  const updateGameCardMenu = useCallback(() => {
    setCardUpdate((prev) =>
      gameCardMenu.map((elem) => {
        elem.initialProgress = currentUser[0]?.progressBar[elem.gameName] || 0;

        return elem;
      })
    );
  }, [currentUser]);

  useEffect(() => {
    currentUser[0] && currentUser[0]._id && trigger(currentUser[0]._id);
  }, [currentUser, trigger]);

  useEffect(() => {
    result.data && currentUser && setSessions(result.data);
    updateGameCardMenu();
  }, [currentUser, result.data, updateGameCardMenu]);

  return { cardUpdate, sessions };
};

export default useGetSessionGameChapter;
