import { FC } from "react";
import "./GenericButton.scss";
import { GenericButtonFC } from "../../models/GenericButtonFC";
import { Link } from "react-router-dom";
import getClasses from "utils/getClasses";
import { useEffectAudio } from "hook/useEffectAudio";

/**
 * A functional component representing a generic button.
 *
 * @param {GenericButtonFC} props - The props for the GenericButton component.
 * @param {boolean} props.isFullWidth - Indicates whether the button should be full-width.
 * @param {string} props.color - The color class name for styling the button.
 * @param {() => void} props.action - The callback function to be executed on button click.
 * @param {string} props.text - The text displayed on the button.
 * @param {boolean} props.disabled - Indicates whether the button is disabled.
 * @returns {JSX.Element} The rendered GenericButton component.
 */
export const GenericButton: FC<Partial<GenericButtonFC>> = ({
    isFullWidth,
    color,
    action,
    text,
    disabled = false,
    size = "large",
    type,
    link,
    buttonType = 'button',
    blockNavigation = false,
}) => {
    const { activePlayButton } = useEffectAudio();

    const commonClasses = `tesi-generic-button ${getClasses({
        lock: blockNavigation,
        "full-width": isFullWidth,
        disabled,
    })} ${color} ${size}`;

    const handleClick = (
        e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
    ) => {
        e.currentTarget.tagName.toLowerCase() === "button" &&
            e.preventDefault();

        if (action) {
            activePlayButton();
            action();
        }
    };

    return (
        <>
            {type === "link" ? (
                <Link
                    data-cy="test-generic-button-link"
                    to={link ?? "/"}
                    className={`${commonClasses} tesi-link`}
                    onClick={handleClick}
                >
                    {text}
                </Link>
            ) : (
                <button
                    type={buttonType}
                    data-cy="test-generic-button"
                    className={commonClasses}
                    onClick={handleClick}
                    disabled={disabled}
                >
                    {text}
                </button>
            )}
        </>
    );
};
