import { CurrentUser } from "models/CurrentUser";
import { useSelector } from "react-redux";
import { authSelector, useGetMeQuery } from "store/features/authSlice";

/**
 * useGetCurrentUser Custom Hook: to get the current user.
 *
 * @returns {Object} - Current user.
 */

const useGetCurrentUser = () => {
    const { isSuccess, isLoading } = useGetMeQuery("");
    const data = useSelector(authSelector);
    let currentUser: CurrentUser | undefined;

    if (!isLoading && isSuccess && data) {
        currentUser = Object.values(data.entities)[0];
    }

    return { currentUser };
};

export default useGetCurrentUser;
