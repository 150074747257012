/* eslint-disable react-hooks/exhaustive-deps */
import { SceneDataT } from "models/ChapterSceneFC";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getAutoPlayTime } from "utils/getAutoplayTime";
import { useEffectAudio } from "./useEffectAudio";

/**
 * useSceneAutoplay:
 * this custom hook handles scene navigation automatically you must provide the
 * following params:
 *
 * @param {number} page - page prop for scene component
 * @param {SceneDataT} contents - scene contents data, it must have objects with
 * empty "title" and "text" for empty pages
 * @param {action} - action prop for scene component.
 * @param {number[]} [avoid] - pages without autoplay
 */

interface IUseSceneAutoPlay {
  page: number;
  contents: SceneDataT;
  action: () => void;
  avoid?: number[];
}

export const useSceneAutoPlay = ({ page, contents, action, avoid }: IUseSceneAutoPlay) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { volumeVoice } = useEffectAudio();
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams([["page", String(page)]]);
    setSearchParams(params);
  }, [page]);

  useEffect(() => {
    if (Boolean(volumeVoice) && action && !avoid?.includes(page)) {
      const currentPageContent = contents[page - 1];
      if (!currentPageContent) {
        console.error(`Content for page ${page} is undefined`);
        return;
      }
      const textExists = currentPageContent.text && currentPageContent.text.length > 0;

      if (isFinished && textExists) {
        action();
      } else if (!textExists) {
        const autoplay = setTimeout(() => action(), 3000);
        return () => clearTimeout(autoplay);
      }
    } else if (Boolean(!volumeVoice) && searchParams.get("page") && !avoid?.includes(page)) {
      const timer = getAutoPlayTime(contents, Number(searchParams.get("page")));
      const autoplay = setTimeout(() => {
        action && action();
      }, timer);
      return () => clearTimeout(autoplay);
    }
    setIsFinished(false);
  }, [searchParams, isFinished]);

  return { setIsFinished, isFinished };
};
