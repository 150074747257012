interface Audio {
    name: string;
    type: "music" | "effect";
    path: string;
}

export const tesiMusics: Audio[] = [
    {
        name: "general",
        type: "music",
        path: "music-background.m4a",
    },
    {
        name: "music-menu",
        type: "music",
        path: "music-menu.m4a",
    },
    {
        name: "interludes",
        type: "music",
        path: "intro-game.m4a",
    },
    {
        name: "demo1",
        type: "music",
        path: "tesi-game-1.m4a",
    },
    {
        name: "total-background",
        type: "music",
        path: "total-background.m4a",
    },
    {
        name: "victory1",
        type: "effect",
        path: "victory-1.m4a",
    },
    {
        name: "button1",
        type: "effect",
        path: "button-1.m4a",
    },
];

export const pathMusic = (type: Audio["type"], name: Audio["name"]) => {
    const audio = tesiMusics.find(
        (audio) => audio.type === type && audio.name === name
    );
    return audio ? audio.path : "";
};
