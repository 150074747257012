import { useState, useEffect } from "react";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export default function usePortraitMode() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const [isPortrait, setIsPortrait] = useState(false);

    useEffect(() => {
        windowDimensions.width < windowDimensions.height
            ? setIsPortrait(true)
            : setIsPortrait(false);
    }, [windowDimensions.height, windowDimensions.width]);

    return { windowDimensions, isPortrait };
}
