import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { environment } from "environments/environment";
import { AuthCredentials } from "models/AuthCredentials";
import {
    removeAuthCredentials,
    setAuthCredentials,
} from "store/slices/tokenSlice";
import { RootState } from "store/store";

const baseQuery = fetchBaseQuery({
    baseUrl: environment.apiUrl,
    prepareHeaders: (headers, { getState }) => {
        const authCredentials = (getState() as RootState).token;
        if (authCredentials.accessToken) {
            headers.set(
                "Authorization",
                `Bearer ${authCredentials.accessToken}`
            );
        } else {
            headers.set(
                "Authorization",
                `Basic ${btoa(`${environment.basicAuthorization}`)}`
            );
        }
        return headers;
        //api.getState() to take element from store like token (example (api.getState() as RootState).token)
    },
});

const baseQueryWithReAuth = async (args: any, api: any, extraOptions: any) => {
    //TODO to type
    try {
        const { expiresDate, refreshToken } = (api.getState() as RootState)
            .token;

        if (expiresDate && Date.now() > expiresDate) {
            const refreshResult = await baseQuery(
                {
                    url: "auth/re-auth",
                    method: "POST",
                    body: { token: refreshToken },
                },
                api,
                extraOptions
            );
            if (refreshResult.data) {
                api.dispatch(
                    setAuthCredentials(refreshResult.data as AuthCredentials)
                );
            } else {
                api.dispatch(removeAuthCredentials());
            }
        }

        //api.getState() to get state of redux
        //api.dispatch(...) to use reducer functions (like useDispatch)
        let result = await baseQuery(args, api, extraOptions);

        return result;
    } catch (error) {
        return {
            error: {
                status: "An error occurred during the API call.",
                originalError: error,
            },
        };
    }
};

export const apiSlice = createApi({
    reducerPath: "api",
    baseQuery: baseQueryWithReAuth,
    tagTypes: ["Auth", "Session"],
    endpoints: () => ({}),
});
