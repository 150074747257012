export interface sentenceMessage {
    visible: string;
    hidden: string;
}

export const sentences: sentenceMessage[] = [
    {
        visible: "Caro il mio investigatore, chi ti scrive è il malfattore!",
        hidden: "",
    },
    {
        visible: "Se davvero mi vuoi trovare questo codice dovrai decifrare.",
        hidden: "",
    },
    {
        visible: "",
        hidden: "“Catturiamo gli animali per interessi personali",
    },
    {
        visible: "",
        hidden: "Della natura ci serviamo e poi la distruggiamo",
    },
    {
        visible: "",
        hidden: "Senza scrupoli, nè pensieri, siamo noi: i bracconieri!”",
    },
];

export const unlockWords = (n: number): sentenceMessage[] => {
    if (n <= 0) return sentences;
    let lengthWordSentence = 0;
    const numberTotalWords = n;
    const sentencesToCopy: sentenceMessage[] = JSON.parse(
        JSON.stringify(sentences)
    );
    const sentencesToProcess = sentencesToCopy.slice(2, sentencesToCopy.length);
    const indexSentence = sentencesToProcess.findIndex((elem, index) => {
        lengthWordSentence += elem.hidden.split(" ").length;
        if (elem && elem.hidden && elem.hidden.split(" ").length > n - 1) {
            return elem;
        } else {
            sentencesToProcess[index].visible =
                sentencesToProcess[index].hidden || "";
            n = n - elem.hidden.split(" ").length;
            sentencesToProcess[index].hidden = "";
            return 0;
        }
    });

    if (numberTotalWords <= lengthWordSentence) {
        sentencesToProcess[indexSentence].visible = sentencesToProcess[
            indexSentence
        ]?.hidden
            ?.split(" ")
            .slice(0, n)
            .join(" ");
        sentencesToProcess[indexSentence].hidden = sentencesToProcess[
            indexSentence
        ]?.hidden
            ?.split(" ")
            .slice(
                n,
                sentencesToProcess[indexSentence]?.hidden?.split(" ").length
            )
            .join(" ");
    }
    return sentencesToCopy.slice(0, 2).concat(sentencesToProcess);
};
