export enum TypeGameEnum {
    START_MODAL_DEMO = "startModalDemo",
    TRAINING_TUTORIAL = "trainingTutorial",
    PLAYING_TUTORIAL = "playingTutorial",
    START_MODAL_GAME = "startModalGame",
    END_MODAL_GAME = "endModalGame",
    SPEECH_GAME = "speechGame",
    SPEECH_GAME_TUTORIAL = "speechGameTutorial",
    PLAY_GAME = "playGame",
}

export enum ModeGameEnum {
    FORWARD_GAME = "forwardGame",
    INTRO_GAME = "introGame",
    REVERSE_GAME = "reverseGame",
}

export enum FlowScene {
    SCENE_FORWARD = "forward",   
    SCENE_REVERSE = "reverse",
    SCENE_END = "end",
    SCENE_SPINOFF_FIRST = "spinoffFirst",
    SCENE_SPINOFF_SECOND = "spinoffSecond",
}
