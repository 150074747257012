export enum EnumPath {
    DASHBOARD = "dashboard",
    GAME_CHAPTERS = "game-chapters",
    INTRO = "intro",
    GAMES = "games",
    PARROT_GAME = "parrot-game",
    TOUCAN_GAME = "toucan-game",
    SCENE = "scene",
    MESSAGE = "message-scene",
    LOGIN = "login",
    SLOTH_GAME = "sloth-game",
}

export enum EnumGame {
    PARROT_GAME = "parrot-game",
    TOUCAN_GAME = "toucan-game",
    SLOTH_GAME = "sloth-game",
}
